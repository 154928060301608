import React from 'react'
import { FlatList } from 'react-native'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Redux, UpDown, OddsFilter } from '../../../../components'
import Coupon from '../coupon'
import ClassicStats from './classic-stats'
import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'

class LiveMatchMarketGroup extends React.Component {

    static defaultProps = {
        g: null
    }

    render() {

        const { g } = this.props

        return (
            <div className="market-group">
                <div className="market-group-in">
                    <div className="market-group-title">
                        {g.typeName}
                        <i className="fas fa-star"></i>
                    </div>
                    <div className="market-group-odds">
                    {
                    g.odds.map((o, index) => {
                        return (
                        <div className={
                            classNames(
                                "market-group-odd", 
                                {
                                    "group-3": Boolean(g.odds.length%3 === 0), 
                                    "group-2": Boolean(g.odds.length%2 === 0)
                                }
                            )} key={`${o.Id}`}>
                            <div className="outcome">
                                {o.outComeName}
                                {
                                o.special.split("*")[0] != "" && (
                                    `(${o.special.split("*")[0]})`
                                )
                                }
                            </div>
                            <div className="odds">{o.odds}</div>
                        </div>
                        )
                    })
                    }
                    </div>
                </div>
            </div>
        );
    }
}

const msp = (state) => {
  return {
      config: state.config,
      coupon: state.coupon,
      member: state.member,
      _mid: state._mid
  }
}

export default LiveMatchMarketGroup
