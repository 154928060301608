import React from 'react'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { Redux, WebClient, constants, UpDown, CFormatDate, CButton } from '../../../../components'
import { FormattedMessage, injectIntl, FormattedDate, FormattedTime } from 'react-intl'
import classNames from 'classnames'
import Swal from 'sweetalert2/src/sweetalert2.js'
import moment from 'moment'

let divHeight = 0
let pages = 1
let activePage = 1
let limit = 0

class AdminTickets extends React.Component {

  constructor(props) {
    super(props)
    this.wc = new WebClient();
    this.state = {
      coupons: [],
      showDetail: false
    }
  } 

  componentDidMount() {

    setTimeout(() => {
        divHeight = this.divElement.clientHeight - 50.75
        let b = Math.ceil(divHeight / 67.8)
        limit = b - 1
        pages = 1
        activePage = 1

        this._getCoupons()

    }, 200)

  }

  _getCoupons() {

      this.wc.post(constants.uris.ucps, 
        {
          limit: limit,
          order: (limit * (activePage - 1))
        }, 
        {
          setReqId: true,
          setUserId: true,
          setLang: true
        }).then((response) => {
          console.log(response)
          if (response.status) {

            let a = response.results
            let c = Math.ceil(response.totalCount / (limit))

            pages = c;

            this.setState({activeTab: 1, coupons: a})
          } else {
            Redux.showToast("error", response.msg)
          }
  
        })

  } 
  
  _getCouponDetail(ticketId) {

    this.wc.post(constants.uris.ucpd, 
      {
        ticketId
      }, 
      {
        setReqId: true,
        setUserId: true,
        setLang: true
      }).then((response) => {
        console.log(response)
        if (response.status) {
            Redux.showTicketDetail(response)
        } else {
          Redux.showToast("error", response.msg)
        }

      })

    }  

    _nextPage() {
        
        if (activePage < pages) {
            activePage = activePage + 1
            this._getCoupons()
        }

    }

    _previousPage() {

        if (activePage > 1) {
            activePage = activePage - 1
            this._getCoupons()
        }

    }  

    // STATUS   -2    CANCEL    #ffab75
    // STATUS   1     WON       #cfffca
    // STATUS   -1    LOST      #ffd5d5

    render() {
        const { coupons, showDetail } = this.state
        return (
            <>
            <div ref={(ref) => { this.divElement = ref }} className="manager-content relative">
            <table className="ticket-result-table">
                <thead>
                    <tr>
                        <th><FormattedMessage id="coupon_no" /></th>
                        <th><FormattedMessage id="date" /></th>
                        <th><FormattedMessage id="bet" /></th>
                        <th><FormattedMessage id="payment" /></th>
                        <th><FormattedMessage id="possible_gain" /></th>
                        <th><FormattedMessage id="bonus" /></th>
                        <th><FormattedMessage id="total" /></th>
                        <th><FormattedMessage id="type" /></th>
                        <th><FormattedMessage id="status" /></th>
                        <th><FormattedMessage id="payment_status" /></th>
                        <th><FormattedMessage id="detail" /></th>
                        <th><FormattedMessage id="cancel" /></th>
                        <th><FormattedMessage id="print" /></th>
                    </tr>
                </thead>
                <tbody>
                    {
                    coupons.map((item, index) => {
                        return (
                        <tr className={classNames({
                            'st-cancel': Boolean(item.status == -2),
                            'st-won': Boolean(item.status == 1),
                            'st-lost': Boolean(item.status == -1),
                            'st-waiting': Boolean(item.status == 2)
                        })} key={index}>
                            <td>{item?.ticketId ?? 0}</td>
                            <td><CFormatDate date={item.ticketDate} /></td>                          
                            <td>{item?.totalStake ?? 0}</td>
                            <td>{item?.income ?? 0}</td>
                            <td>{item?.maxPay ?? 0}</td>
                            <td>{item?.bonusPay ?? 0}</td>
                            <td>{item?.maxPay ?? 0}</td>
                            <td><FormattedMessage id={Redux.getCouponTypeName(item.ticketType)} /> ({item.totalTickets})</td>
                            <td><FormattedMessage id={Redux.getCouponStatusName(item.status)} /></td>
                            <td><FormattedMessage id={item.paid == 1 ? "paid" : "waiting"} /></td>
                            <td>
                            <button onClick={() => { this._getCouponDetail(item.id) }} className="detail-button"><i className="fas fa-search mr-2" /><FormattedMessage id="detail" /></button>
                            </td>
                            <td>{item?.canceled != 0 ? item.canceled : ""}</td>
                            <td>
                            <button onClick={() => { this._resetAccount() }} className="print-button"><i className="fas fa-print mr-2" /><FormattedMessage id="print" /></button>
                            </td>
                        </tr>
                        )
                    })
                    }
                </tbody>
            </table>

            {
            showDetail && (
            <div className="w-full h-full absolute top-0 left-0 bg-cgray-800 bg-opacity-70">

            </div>
            )
            }

            </div>
            <div className="bottom-pagination-cont">
            <CButton width="w-1/3" icon={true} iconName="fa-chevron-left" onClick={() => { this._previousPage() }} />
            <div className="live-pagination-cont" style={{ width: "33%" }}>
                {activePage} / {pages}
            </div>
            <CButton width="w-1/3" icon={true} iconName="fa-chevron-right" onClick={() => { this._nextPage() }} />
            </div>        
            </>
        );
    }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(injectIntl(AdminTickets))
