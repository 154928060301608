import makeUniq from "../helpers/oddUnique";
import { oddParseJson } from "../helpers/format";
// import box from "../store/box";
const allSportsLive = [];
const secondHalfOdds = new Set([41, 33, 35, 8]);
const mProps = Symbol("props");

function firstHalf(match) {
  return (
    match.sportId == "1" &&
    (match.matchstatus == "1p" || match.matchstatus == "not_started")
  );
}
function secondHalf(match) {
  return (
    match.sportId === 1 &&
    match.matchstatus !== "1p" &&
    match.matchstatus !== "not_started"
  );
}
function isStarted(match) {
  // return !(match.matchstatus === "not_started" && !!match.mDate);
  return !(match.matchstatus === "not_started");
}

function matchJson(match) {
  this.home = match.team1;
  this.away = match.team2;
  this.catName = match.catName;
  this.tourName = match.tourName;
  this.mDate = match.mDate;
  this.tourId = match.tournamentId;

  this.sportId = match.sportId;
  this.order = match.order;
  this.catId = match.catId; // for flag

  this.mid = match.mid || 0;

  this.extra = match.extra;
  this.active = match.active;
  this.betstts = match.betstts && match.betstts == 1 ? "started" : "stopped";
  this.matchstatus = match.matchstatus;

  this.score = `${match.score[0]}:${match.score[1]}`;
  this.gamescore = match.gamescore;
  this.setscores = match.setscores;

  this.ttype = match.ttype;
  this.cards = {
    away: match.cards[1],
    home: match.cards[0]
  };
  this.time = match.time;
  this.info = {
    tname: match.tourName,
    flag: match.cf,
    flagW: match.fs
  };
  this.size = 104;
  this.weight = 2;
  if (isStarted(match)) {
    // box.liveMatchIndex.add(this.mid);
  }
  this.odds = match.odds.map(m => oddParseJson(m));
  this.setSecHalf();
}
matchJson.prototype[mProps] = 0;

matchJson.prototype.setSecHalf = function() {
  if (!(this[mProps] & (1 === 1)) && secondHalf(this)) {
    this.size = 54;
    this.weight = 1;
    this[mProps] |= 1;
    if (this.odds.length > 4) {
      let c = 0;
      while (c < this.odds.length) {
        if (
          !(this.odds[c] && this.odds[c].oddstype) ||
          !secondHalfOdds.has(this.odds[c].oddstype)
        ) {
          this.odds.splice(c, 1);
        } else {
          c++;
        }
      }
    }
  }
};

matchJson.prototype.refresh = function(match) {
  this.extra = match.extra;
  this.active = match.active;
  this.betstts = match.betstts ? "started" : "no";
  this.matchstatus = match.matchstatus;
  this.mDate = match.mDate;
  this.order = match.order;
  this.score = `${match.score[0]}: ${match.score[1]}`;
  this.ttype = match.ttype;
  this.cards.away = match.cards.away;
  this.cards.home = match.cards.home;
  this.time.matchtime = match.time.matchtime;
  this.time.matchtimeextended = match.time.matchtimeextended;
  let oddslen = this.odds.length;
  for (let i = 0; i < oddslen; i++) {
    let oOdd = this.odds[i];
    let nOdd = match.odds[i];
    let oLen = oOdd.length;
    oOdd.active = nOdd.active;
    oOdd.mostbalanced = nOdd.mostbalanced;
    oOdd.noddTypeId = nOdd.noddTypeId;
    oOdd.oddsid = nOdd.oddsid;
    oOdd.oddstype = nOdd.oddstype;
    oOdd.special = nOdd.special;
    oOdd.typename = nOdd.typename;
    for (let z = 0; z < oLen; z++) {
      let oOddOdd = oOdd.odds[z];
      let nOdds = nOdd.odds.filter(y => y.uniq == oOddOdd.uniq);
      if (nOdds.length > 0) {
        let nOddOdd = nOdds[0];
        oOddOdd.active = nOddOdd.active;
        oOddOdd.kmas = nOddOdd.kmas;
        oOddOdd.kmay = nOddOdd.kmay;
        oOddOdd.kmis = nOddOdd.kmis;
        oOddOdd.mintip = nOddOdd.mintip;
        oOddOdd.odd = nOddOdd.odd;
        // oOddOdd.noutcomeid = nOddOdd.outComeId; // noutcome gibi seylere gerek kalmadi
        oOddOdd.outcomeid = nOddOdd.outcomeid;
        oOddOdd.pos = nOddOdd.pos;
        oOddOdd.smas = nOddOdd.smas;
        oOddOdd.smay = nOddOdd.smay;
        oOddOdd.smis = nOddOdd.smis;
        oOddOdd.status = nOddOdd.status;
        oOddOdd.ttype = match.ttype;
      }
    }
  }
  this.setSecHalf();
};

matchJson.prototype.updateIt = function(match) {
  this.ttype = "L";
  if (match.hasOwnProperty("extra")) {
    this.extra = match.extra;
  }
  if (match.hasOwnProperty("active")) {
    this.active = match.active;
  }
  if (match.hasOwnProperty("betstatus") && this.betstts !== match.betstatus) {
    this.betstts = match.betstatus;
  }
  if (
    match.hasOwnProperty("matchstatus") &&
    this.matchstatus !== match.matchstatus
  ) {
    this.setSecHalf();
    if (isStarted(this)) {
    //   box.liveMatchIndex.add(this.mid);
    }
    this.matchstatus = match.matchstatus;
  }
  if (
    match.hasOwnProperty("score") &&
    match.score &&
    this.score != match.score.matchscore &&
    match.score.matchscore != undefined
  ) {
    // console.log('UPD SCORE', this.mid, this.score, match.score.matchscore)
    this.score = match.score.matchscore;
  }
  if (!!match.cards) {
    if (
      match.cards.hasOwnProperty("away") &&
      this.cards.away !== match.cards.away
    ) {
      this.cards.away = match.cards.away;
    }
    if (
      match.cards.hasOwnProperty("home") &&
      this.cards.home !== match.cards.home
    ) {
      this.cards.home = match.cards.home;
    }
  }
  if (!!match.time) {
    if (
      match.time.hasOwnProperty("matchtime") &&
      this.time.matchtime !== match.time.matchtime
    ) {
      this.time.matchtime = parseInt(match.time.matchtime);
    //   if (box.liveOrder == 1 && box.matchIndex.includes(match.matchid)) {
    //     const eindPos = box.matchIndex.indexOf(match.matchid);
    //     if (eindPos) {
    //       let nindPos = eindPos;
    //       let takeUp = true;
    //       const mTime = parseInt(match.time.matchtime);
    //       while (takeUp && nindPos--) {
    //         if (
    //           parseInt(box.matches[box.matchIndex[nindPos]].time.matchtime) >=
    //           mTime
    //         ) {
    //           takeUp = false;
    //         }
    //       }
    //       ++nindPos;
    //       // console.log('indi replace', eindPos, nindPos)
    //       if (eindPos != nindPos) {
    //         box.matchIndex.splice(eindPos, 1);
    //         box.matchIndex.splice(nindPos, 0, match.matchid);
    //       }
    //     }
    //   }
    }
    if (
      match.time.hasOwnProperty("matchtimeextended") &&
      this.time.matchtimeextended !== match.time.matchtimeextended
    ) {
      this.time.matchtimeextended = match.time.matchtimeextended;
    }
  }

  if (!!match.odds && Array.isArray(match.odds) && match.odds.length > 0) {
    // const nsAnewO_odds = match.odds.filter(n => {
    //   return box.livesportsids.includes(n.oddstype.toString());
    // });
    const nsAnewO_odds = [] 
    const nsAnewO_oddslen = nsAnewO_odds.length;
    let thisOdds = this.odds;
    if (nsAnewO_oddslen > 0) {
      oddLoop: for (let i = 0; i < nsAnewO_oddslen; i++) {
        const nsAnewO_oddsitem = nsAnewO_odds[i];
        // let nsAnewO_oddskey = nsAnewO_oddsitem.oddsid
        const nsAnewO_oddsoddtype = nsAnewO_oddsitem.oddstype;
        const osAnewO_oddss = thisOdds.filter(
          n => n.oddstype === nsAnewO_oddsoddtype
        );
        if (osAnewO_oddss.length === 1) {
          const osAnewO_odds = osAnewO_oddss[0];
          if (!osAnewO_oddss[0].hasOwnProperty("odds")) {
            // TODO this should be ordered with schema and missing odds should be filled with default vals

            // thisOdds.splice(0,thisOdds.length)
            // for (let izi = 0; izi < nsAnewO_oddslen; izi++) {
            //   thisOdds.push(nsAnewO_odds[izi])
            // }
            break oddLoop;
          }
          let spupdated = false;
          if (nsAnewO_oddsitem.hasOwnProperty("oddsid")) {
            osAnewO_odds.oddsid = nsAnewO_oddsitem.oddsid;
          }
          if (nsAnewO_oddsitem.hasOwnProperty("oddstype")) {
            osAnewO_odds.oddstype = nsAnewO_oddsitem.oddstype;
          }
          if (nsAnewO_oddsitem.hasOwnProperty("special")) {
            osAnewO_odds.special = nsAnewO_oddsitem.special;
            spupdated = true;
          }
          if (
            nsAnewO_oddsitem.hasOwnProperty("active") &&
            osAnewO_odds.active !== nsAnewO_oddsitem.active
          ) {
            osAnewO_odds.active = nsAnewO_oddsitem.active;
          }
          if (nsAnewO_oddsitem.hasOwnProperty("mostbalanced")) {
            osAnewO_odds.mostbalanced = nsAnewO_oddsitem.mostbalanced;
          }
          if (
            !!nsAnewO_oddsitem.odds &&
            Array.isArray(nsAnewO_oddsitem.odds) &&
            nsAnewO_oddsitem.odds.length > 0
          ) {
            const nsAnsAnewO_oddsitem_odds = nsAnewO_oddsitem.odds;
            const nsAnsAnewO_oddsitem_oddslen = nsAnsAnewO_oddsitem_odds.length;
            if (nsAnsAnewO_oddsitem_oddslen > 0) {
              for (let i = 0; i < nsAnsAnewO_oddsitem_oddslen; i++) {
                const nsAnsAnewO_oddsitem_oddsitem =
                  nsAnsAnewO_oddsitem_odds[i];
                const osAnsAnewO_oddsitem_oddss = osAnewO_odds.odds.filter(
                  n => n.outcomeid === nsAnsAnewO_oddsitem_oddsitem.outcomeid
                );
                if (osAnsAnewO_oddsitem_oddss.length === 1) {
                  const osAnsAnewO_oddsitem_odds = osAnsAnewO_oddsitem_oddss[0];
                  if (spupdated) {
                    osAnsAnewO_oddsitem_odds.uniq = makeUniq(
                      nsAnewO_oddsitem.oddstype,
                      nsAnsAnewO_oddsitem_oddsitem.outcomeid,
                      nsAnewO_oddsitem.special
                    );
                  }
                  if (
                    nsAnsAnewO_oddsitem_oddsitem.hasOwnProperty("outcomeid")
                  ) {
                    osAnsAnewO_oddsitem_odds.outcomeid =
                      nsAnsAnewO_oddsitem_oddsitem.outcomeid;
                  }
                  if (nsAnsAnewO_oddsitem_oddsitem.hasOwnProperty("active")) {
                    osAnsAnewO_oddsitem_odds.active =
                      nsAnsAnewO_oddsitem_oddsitem.active;
                  }
                  // if (nsAnsAnewO_oddsitem_oddsitem.hasOwnProperty("outcome")) {
                  //   osAnsAnewO_oddsitem_odds.outcome =
                  //     nsAnsAnewO_oddsitem_oddsitem.outcome;
                  // }
                  if (nsAnsAnewO_oddsitem_oddsitem.hasOwnProperty("odd")) {
                    if (
                      osAnsAnewO_oddsitem_odds.odd >
                      nsAnsAnewO_oddsitem_oddsitem.odd
                    ) {
                      osAnsAnewO_oddsitem_odds.odd =
                        nsAnsAnewO_oddsitem_oddsitem.odd;
                      osAnsAnewO_oddsitem_odds.pos = -1;
                    } else if (
                      osAnsAnewO_oddsitem_odds.odd <
                      nsAnsAnewO_oddsitem_oddsitem.odd
                    ) {
                      osAnsAnewO_oddsitem_odds.odd =
                        nsAnsAnewO_oddsitem_oddsitem.odd;
                      osAnsAnewO_oddsitem_odds.pos = 1;
                    } else {
                      osAnsAnewO_oddsitem_odds.pos = 0;
                    }
                  }
                }
              }
            }
          }
        } else {
          // console.log('insert odd for this match', match.matchid)
        }
      }
    }
  }
};

// module.exports = {
//   matchShort
// }
export { matchJson };
