import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, FormattedDate, FormattedTime } from 'react-intl'
import moment from 'moment'

class CFormatDate extends React.Component {

    static defaultProps = {
        date: "",
        alignItems: "items-center",
        justifyContent: "justify-center"
    }

  render() {
    const { date, alignItems, justifyContent } = this.props
    return (
        <div className={`flex ${alignItems} ${justifyContent} font-roboto`}>
        <FormattedDate
            value={new Date(moment.utc(date).valueOf())}
            year="numeric"
            month="2-digit"
            day="2-digit"
        />
        <span className="mr-m"></span>
        <FormattedTime
            value={new Date(moment.utc(date).valueOf())} hour="2-digit" minute="2-digit"
        />
        </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(injectIntl(CFormatDate))