import React from 'react'
import ConfigWindow from './project/windows/config'
import MainWindow from './project/windows/main'
import { ConfigIntlProviderWrapper } from './project/components/intl-providers/config-intl-provider'
import { MainIntlProviderWrapper } from './project/components/intl-providers/main-intl-provider'

// const electron = window.require('electron')

class App extends React.Component {

  componentDidMount() {
    
  }

  render() {

    let windowType = "main"

    // const { windowType } = electron?.remote?.getGlobal('shareObject')

    if (windowType == "config") {
      return (
        <ConfigIntlProviderWrapper>
          <ConfigWindow />
        </ConfigIntlProviderWrapper>
      );
    } else {
      return (
        <MainIntlProviderWrapper>
          <MainWindow />
        </MainIntlProviderWrapper>
      );
    }

  }
}

export default App;
