import React from 'react'
import { FormattedMessage } from 'react-intl'

class CButton extends React.Component {

    static defaultProps = {
        type: 1,
        icon: false,
        active: false,
        iconName: "",
        width: "w-full",
        height: "h-common",
        uppercase: "",
        text: "",
        flagSee: 1,
        countryFlag: "",
        count: 0,
        onClick: () => {},
        translate: false
    }

    render() {

        const { type, text, active, count, onClick, width, icon, iconName, height, countryFlag, flagSee, uppercase, translate } = this.props

        return (
            <div className={`c-button-cont ${width} ${type == 2 ? height: ""} ${active ? "active" : ""} ${uppercase}`}>

                {
                type == 1 && (
                <button onClick={onClick}>

                    {
                    icon ?
                    <i className={`fas ${iconName}`} />
                    :
                    (
                    translate ? <FormattedMessage id={text} />
                    : 
                    text
                    )
                    }

                </button>
                )
                }

                {
                type == 2 && (
                <button className="c-button-type2" onClick={onClick}>

                    <div className="img-c">
                        <img src={countryFlag} style={{ width: "80%", height: "auto" }} />
                    </div>

                    {
                    translate ?
                    <FormattedMessage id={text} />
                    :
                    text
                    }

                    <span className="count">{count}</span>

                </button>
                )
                }
                
                {
                type == 3 && (
                <button className={`c-button-type3 ${active ? "active" : ""}`} onClick={onClick}>

                    <div className="img-c">
                        {/* <img src={countryFlag} style={{ width: "80%", height: "auto" }} /> */}
                        <i className="fas fa-chevron-right" />
                    </div>

                    <div className="c-button-type3-title">
                    {
                    translate ?
                    <FormattedMessage id={text} />
                    :
                    text
                    }
                    </div>

                    <span className="count">{count}</span>

                </button>
                )
                }                                

            </div>
        );
    }
}

export default CButton
