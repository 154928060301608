import { combineReducers, createStore } from 'redux'
import { injectIntl } from 'react-intl'
import { IntlContext } from '../intl-providers/main-intl-provider'
import { toast } from "react-toastify"
import CSocket from '../socket'
import _ from "lodash"
import constants from '../utils/constants'

const member = (state = null, action) => {
    switch (action.type) {
        case 'SET_MEMBER_DATA':
            return {...state, ...action.payload}
        case 'RESET_MEMBER_DATA':
            return null
        case 'CHANGE_MEMBER_DATA':
            return action.payload
        default:
            return state;
    }
}

const config_state = {
    displayCount: 2,
    externalDisplay: null,
    hasExternalDisplay: true,
    machineId: "ce1b070c75a922da706bde25f5b69140f60ca3ecfb0e0d027e271c26bf6ebe1a",
    printerCount: 6,
    printers: [],
    settings: {
        hasExternalDisplay: true,
        mainScreen: "Main",
        onlineCheck: 1,
        printer: 1,
        secondScreen: "",
        userType: "T",
        version: "0.3.93"
    },
    windowType: "main"
}

const bingo_url = (state = null, action) => {
    switch (action.type) {
        case 'SET_BINGO_URL':
            return action.payload
        default:
            return state;
    }
}

const config = (state = config_state, action) => {
    switch (action.type) {
        case 'SET_CONFIG':
            return action.payload
        default:
            return state;
    }
}

const live_matches = (state = [], action) => {
    switch (action.type) {
        case 'SET_LIVE_MATCHES':
            return action.payload
        case 'UPDATE_MATCH':
            return [...state, {...action.payload}]
        default:
            return state;
    }
}

const coupon = (state = [], action) => {
    switch (action.type) {
        case 'SET_COUPON_DATA':
            return action.payload
        case 'EMPTY_COUPON':
            return []
        case 'ADD_MATCH_TO_COUPON':
            return [...state, {...action.payload}]
        case 'SET_ODD_BANKO':
            let z = [...state]
            let h = z[action.payload]
            let y = !Boolean(h?.is_banko ?? false)
            h.is_banko = y
            return z;
        case 'REMOVE_MATCH_FROM_COUPON':

            let s = [...state];

            let ss = s.filter(x => x.matchid == action.payload.item.id)

            if (ss?.length > 0) {
                ss = ss[0]
                let c = s.indexOf(ss)
                s.splice(c, 1)
            }

            toast.dark(action.intl.formatMessage({id:"match_removed_from_coupon"}), {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                })

            return s            
        case 'ADD_OR_REMOVE_ODD':

            const { item, odd, settings } = action.payload
            
            let unique = `${item.id}-${odd.Id}-${odd.oddsType}-${odd.outComeId}-${odd.special}`
            let b = [...state];
            // let a = b.filter(x => x.matchid == item.id)
            let sm = b.filter(x => x.matchid == item.id)

            let msg = "odd_added_to_coupon"

            if (Boolean(sm.length > 0) && !settings.is_multi_coupon_active) {
                toast.error(action.intl.formatMessage({id:"multi_coupon_not_active_error"}))
                return b;
            }

            if (sm?.length > 0) {
                sm = sm[0]
                let c = b.indexOf(sm)

                let so = sm.odd.filter(x => x.unique == unique)
                if (so.length > 0) {
                    so = so[0]
                    let soi = sm.odd.indexOf(so)
                    sm.odd.splice(soi, 1)
                    msg = "odd_removed_from_coupon"
                } else {
                    sm.odd.push({...odd, unique})
                }

                if (sm.odd.length == 0) {
                    b.splice(c, 1)
                }

            } else {

                if (sm.length > 0) {

                    let smm = sm[0]
                    smm.odd.push({...odd, unique})

                } else {

                    let o = []
                    o.push({...odd, unique})

                    b.push({
                        matchid: item.id,
                        item,
                        odd: o
                    })
                }

            }

            toast.dark(action.intl.formatMessage({id:msg}), {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                })

            // console.log(b)

            return b
        default:
            return state;
    }
}

const language = (state = "en", action) => {
    switch (action.type) {
        case 'SET_LANGUAGE':
            return action.payload
        default:
            return state;
    }
}

const coupons = (state = [], action) => {
    switch (action.type) {
        case 'SET_COUPONS':
            return action.payload
        default:
            return state;
    }
}

const loading = (state = false, action) => {
    switch (action.type) {
        case 'SHOW_LOADING':
            return true
        case 'HIDE_LOADING':
            return false         
        default:
            return state;
    }
}

const settings = (state = null, action) => {
    switch (action.type) {
        case 'SET_SETTINGS':
            return action.payload
        default:
            return state;
    }
}

const smatches = (state = [], action) => {
    switch (action.type) {
        case 'SET_SMATCHES':
            return action.payload
        case 'ADD_SMATCHES':
            return [...state, ...action.payload]
        default:
            return state;
    }
}

const ready = (state = false, action) => {
    switch (action.type) {
        case 'SET_READY':
            return action.payload
        default:
            return state;
    }
}

const live_active_sport = (state = 1, action) => {
    switch (action.type) {
        case 'SET_LIVE_ACTIVE_SPORT':
            return action.payload
        default:
            return state;
    }
}

const _mid = (state = null, action) => {
    switch (action.type) {
        case 'SET_MID':
            return action.payload
        default:
            return state;
    }
}

const coupon_settings = (state = null, action) => {
    switch (action.type) {
        case 'SET_COUPON_SETTINGS':
            return action.payload
        case 'SET_MIN_ODD_LIMIT':
            return {...state, minOddsLimit: action.payload}
        case 'SET_MAX_ODD_LIMIT':
            return {...state, maxOddsLimit: action.payload}                        
        default:
            return state;
    }
}

const terminal_pass = (state = "", action) => {
    switch (action.type) {
        case 'SET_TERMINAL_PASS':
            return action.payload
        default:
            return state;
    }
}

let show_ticket_detail_state = {
    show: false,
    data: null
}

const show_ticket_detail = (state = show_ticket_detail_state, action) => {
    switch (action.type) {
        case 'SHOW_TICKET_DETAIL':
            return {
                show: true,
                data: action.payload
            }
        case 'HIDE_TICKET_DETAIL':
            return {
                show: false,
                data: null
            }
        default:
            return state;
    }
}

const combinations = (state = [], action) => {
    switch (action.type) {
        case 'SET_COMBINATIONS':
            return action.payload
        case 'CLEAR_COMBINATIONS':
            return []
        default:
            return state;
    }
}

const selected_combinations = (state = [], action) => {
    switch (action.type) {
        case 'SET_SELECTED_COMBINATIONS':
            return action.payload
        case 'CLEAR_SELECTED_COMBINATIONS':
            return []
        default:
            return state;
    }
}

const coupon_bottom_default = {
    type: "TK",
    bet_amount: 1,
    total_stake: 0,
    total_rate: 0,
    coupon_number: 0,
    odd_count: 0,
    total_odds: 0,
    max_gain: 0,
    total: 0,
    bet_trigger: 1,
    accept_rate_changes: true
}

const coupon_bottom = (state = coupon_bottom_default, action) => {
    switch (action.type) {
        case 'SET_COUPON_BOTTOM':
            return action.payload
        case 'UPDATE_COUPON_BOTTOM':
            return {...state, ...action.payload}
        case 'CLEAR_COUPON_BOTTOM':
            return coupon_bottom_default;            
        default:
            return state;
    }
}

const reducer = combineReducers({
    member,
    config,
    live_matches,
    coupon,
    language,
    loading,
    settings,
    smatches,
    ready,
    coupons,
    live_active_sport,
    _mid,
    coupon_settings,
    terminal_pass,
    show_ticket_detail,
    combinations,
    coupon_bottom,
    selected_combinations,
    bingo_url
})

const store = createStore(reducer);

function setBingoUrl(payload) {
    store.dispatch({
        type: "SET_BINGO_URL",
        payload
    })
}

function setCombinations(payload) {
    store.dispatch({
        type: "SET_COMBINATIONS",
        payload
    })
}

function clearCombinations() {
    store.dispatch({
        type: "CLEAR_COMBINATIONS"
    })
}

function setSelectedCombinations(payload) {
    store.dispatch({
        type: "SET_SELECTED_COMBINATIONS",
        payload
    })
}

function addOrRemoveSelectedCombination(payload, intl) {

    let a = store.getState().combinations

    a.forEach((e) => {
        e.selected = false
    })

    payload.forEach(function (e) {
        let b = a.filter(x => x.combine == e.combine)
        if (b.length > 0) {
            b[0].selected = true
        }
    })

    store.dispatch({
        type: "SET_COMBINATIONS",
        payload: a
    })

    calculateCoupon(intl)
}

function clearSelectedCombinations() {
    store.dispatch({
        type: "CLEAR_SELECTED_COMBINATIONS"
    })
}

function setCouponBottom(payload) {
    store.dispatch({
        type: "SET_COUPON_BOTTOM",
        payload
    })
}

function updateCouponBottom(payload) {
    store.dispatch({
        type: "UPDATE_COUPON_BOTTOM",
        payload
    })
}

function clearCouponBottom() {
    store.dispatch({
        type: "CLEAR_COUPON_BOTTOM"
    })
}

function setConfig(payload) {
    store.dispatch({
        type: "SET_CONFIG",
        payload
    })
}

function setReady(payload) {
    store.dispatch({
        type: "SET_READY",
        payload
    })
}

function showTicketDetail(payload) {
    store.dispatch({
        type: "SHOW_TICKET_DETAIL",
        payload
    })
}

function hideTicketDetail() {
    store.dispatch({
        type: "HIDE_TICKET_DETAIL"
    })
}

function setSettings(payload) {
    store.dispatch({
        type: "SET_SETTINGS",
        payload
    })
}

function setTerminalPass(payload) {
    store.dispatch({
        type: "SET_TERMINAL_PASS",
        payload
    })
}

function setSportMatches(payload) {
    store.dispatch({
        type: "SET_SMATCHES",
        payload
    })
}

function addSportMatches(payload) {
    store.dispatch({
        type: "ADD_SMATCHES",
        payload
    })
}

function setOddBanko(payload, intl) {
    store.dispatch({
        type: "SET_ODD_BANKO",
        payload
    })

    calculateCoupon(intl)
}

function emptyCoupon() {
    
    store.dispatch({
        type: "EMPTY_COUPON"
    })

    clearCouponBottom()
    clearSelectedCombinations()
    clearCombinations()
}


function addOrRemoveOdd(item, odd, intl) {

    const settings = store.getState().coupon_settings

    store.dispatch({
        type: "ADD_OR_REMOVE_ODD",
        payload: {
            item,
            odd,
            settings
        },
        intl
    })

    calculateCoupon(intl)

}

function removeMatchFromCoupon(item, odd, intl) {
    
    store.dispatch({
        type: "REMOVE_MATCH_FROM_COUPON",
        payload: {
            item,
            odd
        },
        intl
    })

    clearCombinations()
    calculateCoupon(intl)
}

function getSubArrays(arr) {
    var len = arr.length,
        subs = Array(Math.pow(2,len)).fill();
    return subs.map((_,i) => { var j = -1,
                                k = i,
                                res = [];
                            while (++j < len ) {
                                k & 1 && res.push(arr[j]);
                                k = k >> 1;
                            }
                            return res;
                            }).slice(1);
}

function getSystemTotalRate(combinations)
{
    const { coupon } = store.getState()

    let odds = []
    let rate = 0

    // const bankos = coupon.reduce((a, o) => (o?.is_banko && a.push(o.matchid), a), [])    
    
    const bankos = coupon.filter(a => a?.is_banko === true).reduce((a, o) => (a.push(o.matchid), a), [])   

    // console.log("bankos nigga", bankos)

    coupon.forEach((i) => {
        i.odd.forEach((y) => {
            odds.push({id: y.matchId, odd: y.odds})
        })
    })

    let v = getSubArrays(odds)
    let f = []

    // console.log("combinasyon", combinations.filter(x => x.selected == true))

    combinations.filter(x => x.selected == true).forEach((c) => {
        v.forEach((x) => {
            // console.log("x.length", x.length)
            if (x.length == c.combine) {
                if (bankos.length > 0) {
                    let has = true
                    bankos.forEach((b) => {
                        if (x.filter(g => g.id == b).length == 0) has = false
                    })

                    if (has) f.push(x)

                } else {
                    f.push(x)
                }
            }
        })
    })

    // console.log("f ----------------------------->", f)

    f.forEach((o) => {
        let y = 1;
        o.forEach((u) => {
            y = y * u.odd
        })
        rate = rate + y
    })

    return rate;
}   

function prepareSystemIds(l, combinations)
{
    const { coupon } = store.getState()

    let odds = []
    let rate = 0

    coupon.forEach((i) => {
        i.odd.forEach((y) => {
            odds.push({id: y.matchId, odd: y.odds})
        })
    })

    let v = getSubArrays(odds)
    let f = []

    combinations.filter(x => x.selected == true).forEach((c) => {
        v.forEach((x) => {

            if (x.length == c.combine) {
                if (l.length > 0) {
                    x.forEach(element => {
                        if (l.filter(t => t == element.id).length > 0) f.push(x)
                    });
                } else {
                    f.push(x)
                }
            }
        })
    })

    // console.log("f ----------->", f)

    return f;
}   

function calculateCoupon(intl) {
    const { coupon, combinations, coupon_bottom } = store.getState()
    // console.log("coupon", coupon)

    let coupon_type = "TK"
    const cl = coupon.length
    let calculated_combinations = []

    let disabled = Boolean(cl == 0)
    let total_rate = cl == 0 ? 0 : 1;
    let coupon_number = cl == 0 ? 0 : 1;
    let odd_count = 0;
    let bankos = coupon.filter(x => x?.is_banko == true)

    if (cl > 0) {

        if (coupon.length > 1) coupon_type = "KK"

        coupon.forEach((i) => {
            if (i.odd.length > 1) {
                coupon_type = "CK"
            }

            odd_count = odd_count + i.odd.length
            coupon_number = coupon_number * i.odd.length

            let cc = _.orderBy(i.odd, ['odds'],['desc']);
            total_rate = total_rate * cc[0].odds

        })

        for(let n = 1; n <= cl; n++) {

            let selected = combinations.filter(x => x.combine == n)[0]?.selected ?? false

            if (bankos.length > 0 && selected) selected = !Boolean(n <= bankos.length && selected)

            calculated_combinations.push({
                label: intl.formatMessage({id: "combine_coupon"}, {count: n}),
                combine: n,
                coupon_count: combination(cl, n),
                selected
            })
        }

        if (combinations.filter(x => x.selected == true).length > 0) {
            coupon_type = "SK"
            coupon_number = calculated_combinations.filter(x => x.selected == true).reduce((a, b) => a + (b.coupon_count || 0), 0)

            total_rate = getSystemTotalRate(calculated_combinations);

            if (bankos.length > 0) {
                // console.log("bankos", bankos)
                // let ids = prepareSystemIds(bankos.length, calculated_combinations)

                
            }
        }

        // console.log("total_rate", total_rate)

        let total = (coupon_bottom.bet_amount) * total_rate; 


        let cb = {
            type: coupon_type,
            total_stake: coupon_bottom.bet_amount,
            total_rate: total_rate,
            coupon_number: coupon_number,
            total_odds: total_rate,
            max_gain: total,
            total: total,
            odd_count: odd_count
        }

        // console.log("cb", cb)

        updateCouponBottom(cb)
        setCombinations(calculated_combinations)

    } else {
        clearCouponBottom()
        clearCombinations()
        clearSelectedCombinations()
    }    

}

function getBankoCount() {
    const { coupon } = store.getState()
    return coupon.filter(x => x?.is_banko === true)?.length ?? 0
}

function factorial(number){
    var value = number;
    for ( var i = number; i > 1; i-- )
        value *= i - 1;
    return value;
};    

function combination(n, r) {

    let banko_count = getBankoCount()

    if (banko_count > 0) {
        n = n - banko_count
        r = r - banko_count
    }

    if ( n == r) return 1;
    return factorial(n) / (factorial(r) * factorial(n - r));
}

function setMemberData(payload) {

    let minOddsCoupon = parseFloat(payload?.minOddsCoupon?.limit) ?? 0
    let maxOddsCoupon = parseFloat(payload?.maxOddsCoupon?.limit) ?? 0

    let coupon_status = {
        combine_coupon: convertCouponLimitData(payload.kk),
        is_combine_coupon_active: Boolean(payload?.kk?.status == 1),
        system_coupon: convertCouponLimitData(payload.sk),
        is_system_coupon_active: Boolean(payload?.sk?.status == 1),
        multi_coupon: convertCouponLimitData(payload.ck),
        is_multi_coupon_active: Boolean(payload?.ck?.status == 1),
        live_coupon: convertCouponLimitData(payload.lk),
        is_live_coupon_active: Boolean(payload?.lk?.status == 1),
        single_coupon: convertCouponLimitData(payload.tk),
        is_single_coupon_active: Boolean(payload?.tk?.status == 1),
        minOddsCoupon,
        maxOddsCoupon
    }
    
    store.dispatch({
        type: "SET_MEMBER_DATA",
        payload
    })

    store.dispatch({
        type: "SET_COUPON_SETTINGS",
        payload: coupon_status
    })

    store.dispatch({
        type: "SET_READY",
        payload: true
    })
}

function convertCouponLimitData(d) {

    let status = 0; 
    let maxPay = 0;
    let maxStake = 0;
    let minStake = 0; 
    let minTip = 0;

    if (d?.status) {
        status = d.status
    }

    if (d?.maxPay) {
        maxPay = parseFloat(d.maxPay)
    }

    if (d?.maxStake) {
        maxStake = parseFloat(d.maxStake)
    }

    if (d?.minStake) {
        minStake = parseFloat(d?.minStake) ?? 0
    }

    if (d?.minTip) {
        minTip = parseFloat(d.minTip)
    }

    return {
        status,
        maxPay,
        maxStake,
        minStake,
        minTip
    }

}

function setLiveMatches(payload) {

    store.dispatch({
        type: "SET_LIVE_MATCHES",
        payload
    })
}

function updateMatch(payload) {
    store.dispatch({
        type: "UPDATE_MATCH",
        payload
    })
}

function setCouponData(payload) {
    store.dispatch({
        type: "SET_COUPON_DATA",
        payload
    })
}

function addMatchToCoupon(payload) {
    store.dispatch({
        type: "ADD_MATCH_TO_COUPON",
        payload
    })
}

function setLanguage(payload) {
    store.dispatch({
        type: "SET_LANGUAGE",
        payload
    })
}

function showLoading() {
    store.dispatch({
        type: "SHOW_LOADING"
    })
}

function hideLoading() {
    store.dispatch({
        type: "HIDE_LOADING"
    })
}

function setCoupons(payload) {
    store.dispatch({
        type: "SET_COUPONS",
        payload
    })
}

function setLiveActiveSport(payload) {
    store.dispatch({
        type: "SET_LIVE_ACTIVE_SPORT",
        payload
    })
}

function setMid(payload) {
    store.dispatch({
        type: "SET_MID",
        payload
    })
}

function getMatchById(matchid) {
    let a = store.getState().live_matches
    // console.log(a)
    return a.filter(x => x.matchid == matchid)[0]
}

function showToast (type = 'success', message) {

    let opts = {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
    }

    switch (type) {
        case 'error':
            return toast.error(message, opts)
        case 'warning':
            return toast.warning(message, opts)
        case 'success':
            return toast.success(message, opts)
    }
}

function getStatisticsUrl(m) {
    let s = store.getState();
    let ss = s.settings

    if (ss?.settings?.statistic_url?.length > 0) {
        let language = s.language
        let url = ss.settings.statistic_url;

        url = url.replace("(#lang)", language)
        url = url.replace("(#mid)", m)
        // console.log(language, url)
        return url;
    }

    return ""
}

// 

function getLiveStatisticsUrl(m) {
    let s = store.getState();
    let settings = s.settings
    let language = s.language

    if (m?.eid || m?.externalId) {
        let a = m?.eid ?? m?.externalId
        return {url:`${constants.base_url}/betradar/index.php?matchId=${a}&lang=${language}`, type: 1}
    } else {
        return {url: `https://href.li/?https://animations.namerandomness.com/#/widget/animation?gameid=${m.matchid}`, type: 2}
    }
}

function getNormalOddsBySportId(id) {
    // console.log("WINDOW")
    // console.log(window)
    // let obj = window?.normalsportsnew ?? window?.normalsports;
    let obj = window?.normalsports;

    let z = obj[id]

    if (z == undefined || z === null) return null;
    // console.log(JSON.parse(z.settings))
    return JSON.parse(z.settings)[0]?.odds;
}

function getLiveOddsBySportId(id) {
    // console.log("LIVE ODDS")
    let obj = window?.livesportsnew ?? window?.livesports;

    let z = obj[id]

    if (z == undefined || z === null) return null;

    // console.log(JSON.parse(z.settings))

    let o = JSON.parse(z.settings)

    // console.log(o)

    return o;
}

function getTypeNames(id) {

    let obj = window?.outcomes;
    let lang = store.getState().language


    let z = obj[id][lang]

    return z
}

function getLiveTypeNames(id) {

    let obj = window?.outcomeslive;
    let lang = store.getState().language


    let z = obj[id][lang]

    return z
}

function getSelectedLiveTabs(id) {

    let items = localStorage.getItem(`live_tabs_${id}`)

    if (items != null) {
        return JSON.parse(items)
    } else {

        let a = constants.defaultColumns.filter(x => x.sid == id)
        if (a.length > 0) {
            a = constants.defaultColumns.filter(x => x.sid == id)[0].markets
        } else {
            a = []
        }

        return a
    }

}

function getSelectedLiveTabsForSecondScreen(id) {

    let items = localStorage.getItem(`live_tabs_second_screen_${id}`)

    if (items != null) {
        return JSON.parse(items)
    } else {

        let a = constants.defaultColumns.filter(x => x.sid == id)
        if (a.length > 0) {
            a = constants.defaultColumns.filter(x => x.sid == id)[0].markets
        } else {
            a = []
        }

        return a
    }

}

function updateSelectedLiveTabsForSecondScreen(id, a) {

    localStorage.setItem(`live_tabs_second_screen_${id}`, JSON.stringify(a))

    return getSelectedLiveTabsForSecondScreen(id)

}

function updateSelectedLiveTabs(id, a) {

    localStorage.setItem(`live_tabs_${id}`, JSON.stringify(a))

    return getSelectedLiveTabs(id)

}

function getCouponTypeName(a) {
    if (a == "SK") {
        return "system_coupon_title"
    } else if (a == "CK") {
        return "multi_coupon_title"
    } else if (a == "KK") {
        return "combine_coupon_title"
    } else if (a == "LK") {
        return "live_coupon_title"
    } else if (a == "TK") {
        return "single_coupon_title"
    }

    return ""
}

function getCouponStatusName(a) {
    if (a == "-2") {
        return "canceled"
    } else if (a == "-1") {
        return "lost"
    } else if (a == "1") {
        return "won"
    }

    return "waiting"
}

function getSelectionVisibilityStatus(match, market, selection) {

    let match_iss = match.betstatus == "stopped" ? true : match.iss
    let match_isv = match.betstatus == "stopped" ? false : match.isv

    let market_iss = match_iss ? true : market.iss
    let market_isv = !match_isv ? false : market.isv

    let iss = market_iss ? true : selection.iss
    let isv = !market_isv ? false : selection.isv

    if (!isv) return "locked"
    if (iss) return "locked"

    return ""

}

export {
    store,
    setConfig,
    setLiveMatches,
    setCouponData,
    addMatchToCoupon,
    removeMatchFromCoupon,
    updateMatch,
    getMatchById,
    showToast,
    setLanguage,
    showLoading,
    hideLoading,
    setMemberData,
    setSettings,
    getNormalOddsBySportId,
    setSportMatches,
    setReady,
    getStatisticsUrl,
    addSportMatches,
    setCoupons,
    getLiveOddsBySportId,
    setLiveActiveSport,
    setMid,
    addOrRemoveOdd,
    setOddBanko,
    emptyCoupon,
    setTerminalPass,
    getLiveTypeNames,
    showTicketDetail,
    hideTicketDetail,
    getCouponTypeName,
    getCouponStatusName,
    setCombinations,
    clearCombinations,
    setCouponBottom,
    updateCouponBottom,
    clearCouponBottom,
    setSelectedCombinations,
    clearSelectedCombinations,
    addOrRemoveSelectedCombination,
    getBankoCount,
    calculateCoupon,
    setBingoUrl,
    getTypeNames,
    getSelectedLiveTabs,
    updateSelectedLiveTabs,
    getSelectionVisibilityStatus,
    getSelectedLiveTabsForSecondScreen,
    updateSelectedLiveTabsForSecondScreen,
    getLiveStatisticsUrl
}