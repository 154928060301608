import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { Redux, WebClient, constants } from '../../components'
import assets from '../../components/utils/assets'
import moment from 'moment'
import _ from 'lodash'
import LiveMatchRow from './live-match-row'
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized'
import { addCoupon, removeOdd, addOdd, oddExists } from '../../components/utils/coupon'

import "../../../assets/common/grids.css"

let tournament_title = ""
let showTitle = true;

class MatchList extends React.Component {

    static defaultProps = {
        showTitle: false,
        index: 0,
        showAllList: false
    }

    componentDidUpdate() {
        tournament_title = ""
    }

    couponAction(e, indis, oid, rate, add = true) {

        console.log(e, oid, rate)

        let res = add
        ? addOdd({
            t: e?.ttype == "L" ? 1 : 2,
            m: parseInt(e.id),
            d: e,
            o: rate,
            b: false,
            i: oid.oddsType,
            n: indis,
            od: oid
          })
        : removeOdd(e.id, rate.uniq);
        
        

    }

    render() {
        const { live_matches, schema, z, l, orderBy, showAllList, halfTime } = this.props

        let matches = live_matches
        
        let a = live_matches.filter(x => x.matchtime > 0)
        let b = live_matches.filter(x => x.matchtime == 0)
        
        a = _.orderBy(a, ['matchtime'],['desc']);
        b = _.orderBy(b, ['matchDate'], ['asc']);

        matches = [...a, ...b];
        
        return (
            <>
            {
              matches.map((item, index) => {
                let oe = Boolean(index%2 == 0) ? "odd" : "even"

                let active = true

                if (!showAllList) {
                    active = Boolean(item.matchstatus != "not_started")
                }

                if (active == false) return null
                if (item.matchstatus == "ended") return null

                return (
                    <LiveMatchRow key={index} z={z} l={l} schema={schema} item={item} oe={oe} halfTime={halfTime} />
                )
              })
            } 
            </>          
        );
    }
}

const msp = (state) => {
    return {
        config: state.config,
        member: state.member,
        live_matches: state.live_matches
    }
  }
  
  export default connect(msp)(injectIntl(MatchList))
