import React from 'react'
import { connect } from 'react-redux'
import { Redux, WebClient, constants } from '../../../components'

class LiveBingo extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      gameUrl: ""
    }
  } 

  componentDidMount() {
    window.addEventListener('message', this.handleIframeTask);
    // this._getBingoUrl()
  }

  // _getBingoUrl() {
  //   let cc = new WebClient();
  //   cc.post(constants.uris.bngo, 
  //     {
  //       token: Math.random().toString(36).substr(2)
  //     }, 
  //     {
  //       setReqId: true,
  //       setMachineId: false,
  //       setUserId: true,
  //       setLang: true
  //     }).then((response) => {
  //       console.log(response)
  //       console.log(this.props.config)
  //       if (response.status) {

  //         let video = 1;
  //         if (this.props.config.settings.secondScreen == "TombalaVideo") video = 0

  //         let gameUrl = `${response.content.gameurl}&terminal=1&video=${video}&sendData=1&origin=http://localhost:3000`
  //         console.log(gameUrl)
  //         this.setState({gameUrl: gameUrl, loading: false})
  //       }
  //     }).catch((err) => {
  //         console.log(err)
  //     })
  // }  

  handleIframeTask = (e) => {
    if (e.origin !== 'https://embed.tombalaklas.com') {
      return;
    }

    console.log(e.data)

    Redux.setMemberData({userCredit: e.data.credit.toFixed(2)})

  };  

  render() {

    const { loading, gameUrl } = this.state
    const { bingo_url } = this.props
    console.log(bingo_url)
    return (
      <div className="h-full w-full">
        {
        (bingo_url != null) && (
          <iframe 
            style={{ width: "100%", height: "100%" }} src={bingo_url}></iframe>
        )
        }
      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config,
      bingo_url: state.bingo_url
  }
}

export default connect(msp)(LiveBingo)
