import React from 'react'
import { connect } from 'react-redux'
import * as Redux from '../../../../components/redux'

class CIframe extends React.Component {

  constructor(props) {
    super(props)
  } 

  componentDidMount() {
    window.addEventListener('message', this.handleIframeTask);
  }

  handleIframeTask = (e) => {
    if (e.origin !== 'https://res.webapi.network') {
      return;
    }

    console.log(e)

    if (e.data?.name == "balanceChanged") {
        console.log(e.data.balanceAfter)
        Redux.setMemberData({userCredit: e.data.balanceAfter.toFixed(2)})
    }

    console.log(e)

  }; 

  render() {

    const { gameUrl } = this.props

    return (
        <div className="iframe-cont">
            <button onClick={() => { this.props.onClose() }} className="iframe-close"><i className="fas fa-times" /></button>

            <iframe 
            style={{ width: "100%", height: "100%" }} src={gameUrl}></iframe>

        </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(CIframe)
