import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { Redux, WebClient, constants } from '../../../../components'
import assets from '../../../../components/utils/assets'
import CButton from './button'
import moment from 'moment'
import _ from 'lodash'
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized'
import { addCoupon, removeOdd, addOdd, oddExists } from '../../../../components/utils/coupon'
import classNames from 'classnames'
import "../../../../../assets/common/grids.css"

class LiveMatchRow extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            item: props.item,
            schema: props.schema,
            z: props.z,
            l: props.l,
            oe: props.oe,
            _mid: props._mid,
            halfTime: props.halfTime
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps?.schema != this.state.schema) {
            return true
        }

        if (nextProps?._mid == this.state.item?.matchid) {
            // console.log(this.state.item.matchid, "<-------------------------------------------- UPDATED")
            // console.log(this.props.item)
            return true
        }

        return false;
    }

    _removeAnimate(mid, oid) {
        setTimeout(() => {
            oid.animate = undefined
            Redux.setMid(mid)
        }, 1500)
    }

    _setFavorite(m, st) {
        m.is_favorite = !st
        Redux.setMid(m.matchid)
        this.props.onAddFavorite(!st ? 1 : -1)
    }

    render() {
        const { item, schema, z, l, oe, _mid, halfTime } = this.props

        if (item.matchstatus == "ended") return null
        
        const scores = item.score.split(":")
        const score_home = parseInt(scores[0])
        const score_away = parseInt(scores[1])

        const is_first_period = Boolean((item.sportId == 1 && item.matchtime <= halfTime && item.matchstatus == "1p") || (item.sportId == 1 && item.matchstatus == "not_started"))

        const is_tie = score_home == score_away
        const is_threshold = Boolean((score_home + score_away) <= 2)

        return (
        <div className="w-full"> 
            <div className={classNames({"match-row-cont": !is_first_period, "match-row-cont-fp": is_first_period})}>
            {
            schema.map((s, sindex) => {
                if (s.y == "o" && !s.ics) return null
                return (
                    <div key={sindex} data-box={z} data-col={l} 
                        className={classNames({
                            [`m-${s.y}`]: Boolean(s.y != "t" || (!is_first_period && s.y == "t")),
                            [`m-t-2`]: Boolean(s.y == "t" && is_first_period),
                            [`i-c-${oe}`]: true,
                            'bg-live': Boolean(item?.matchstatus != "paused" && item?.matchstatus != "stopped" && item?.matchstatus != "not_started"),
                            'bg-not-started': Boolean(item?.matchstatus == "not_started"),
                            'bg-half-time': Boolean(item?.matchstatus == "paused"),
                            'bg-stopped': Boolean(item?.matchstatus == "stopped")
                          })}>

                        {
                        s.y == "d" && (
                            <>
                            {
                            item?.matchtime > 0 ?
                            (
                            item?.matchstatus == "paused" ?
                            <span>HT</span>
                            :
                            <span>{item.matchtime}<span className="second-blink">'</span></span>
                            )
                            :
                            <>
                            <span>{moment(item.matchDate).format("DD/MM")}</span>
                            <span>{moment(item.matchDate).format("HH:mm")}</span>
                            </>
                            }
                            </>
                        )
                        }

                        {
                        s.y == "t" && (
                            <>
                            <div className="live-match-teams-score">

                                <div className="live-match-teams-flag">
                                    <span>{item.tournamentName}</span>
                                    <span>{item.categoryName} <img src={item.categoryFlag} /></span>
                                </div>

                                <div className="live-match-teams">

                                    {
                                    item?.redcardshome > 0 && (
                                    <span className="red-card-home"></span>
                                    )
                                    }

                                    {
                                    item?.redcardsaway > 0 && (
                                    <span className="red-card-away"></span>
                                    )
                                    }                                    

                                    <div className="live-match-teams-home">
                                        <span>{item.Home}</span>
                                    </div>

                                    <div className="live-match-score">
                                        <span>{item.score}</span>
                                    </div>

                                    <div onClick={() => { this._setFavorite(item, item?.is_favorite ?? false) }} className="live-match-favorite">
                                        <i 
                                            className={classNames({
                                                "fas": item?.is_favorite,
                                                "far": !item?.is_favorite,
                                                "fa-star": true,
                                                "text-yellow-500": item?.is_favorite
                                            })} 
                                        /> 
                                    </div>                                    

                                    <div className="live-match-teams-away">
                                        <span>{item.Away}</span>
                                    </div>
                                </div>
                            </div>
                            {
                            is_first_period && (
                            <div className="live-match-fh-name">
                                <span className="live-row-priod-name"><FormattedMessage id="first_period" /></span>
                            </div>                                
                            )
                            }
                            </>
                        )
                        }        

                        {
                        (s.y == "o" && s.ics) && (
                            <>
                            <div className={`odd-group odd-group-${s.o.length}`}>
                                {
                                s.o.map((_o, oindex) => {

                                    let o = _o

                                    let st = "locked"
                                    let g = undefined
                                    let j = undefined
                                    
                                    let _g = item?.odds?.filter(x => x.oddsType == o.t && x.iss == false && x.isv == true && x.odds.find(y => y.isv == true && y.iss == false)).sort((a, b) => a.specialvalue - b.specialvalue)

                                    if (is_tie && o.t == 12553 && _g.length == 0) {
                                        _g = item?.odds?.filter(x => x.oddsType == 5498 && x.iss == false && x.isv == true && x.odds.find(y => y.isv == true && y.iss == false))
                                        let _s = schema.filter(x => x.i == 5498)
                                        if (_s?.length > 0) {
                                            _s = _s[0]
                                            o = _s?.o[oindex] ?? _o
                                        }
                                    }

                                    if (_g?.length > 0) {      

                                        g = _g[0]
                                        
                                        if (item.sportId == 1) {
                                            if (is_threshold && o.t == 5500) {
                                                _g = _g.filter(x => x.specialvalue == 2.5)
                                                if (_g.length > 0) {
                                                    g = _g[0]
                                                }
                                            } else if (o.t == 5500) {
                                                _g = _g.filter(x => x.specialvalue % 1 > 0)
                                                if (_g.length > 0) {
                                                    g = _g[0]
                                                }
                                            }
                                        }

                                        let k = g?.odds?.filter(x => x.outComeId == o.i)
                                       
                                        if (k?.length > 0) {
                                            j = k[0]
                                        }
                                    }                                    

                                    let odd = parseFloat(j?.odds.toFixed(4) ?? 1)
                                    if (odd > 1) st = Redux.getSelectionVisibilityStatus(item, g, j)

                                    return (
                                        <div key={`${j?.Id ?? oindex+100000}-${oindex}`} className={classNames({
                                                "live-odd-button-cont": true
                                                })}>
                                                
                                                {
                                                o.o == ".s" && (
                                                <div className="odd-special">
                                                {g?.specialvalue ?? "-"}
                                                </div>
                                                )
                                                }

                                                {
                                                (o.o != ".s" && st == "") && (
                                                <div className="active-odd">
                                                {odd.toFixed(2)}
                                                </div>
                                                )
                                                }

                                                {
                                                (o.o != ".s" && st == "locked") && (
                                                <div className="locked-odd">
                                                
                                                </div>                                          
                                                )
                                                }

                                                {
                                                (o.o != ".s" && st == "semi-locked") && (
                                                <div className="semi-locked-odd">
                                                {odd.toFixed(2)}
                                                </div>                                          
                                                )
                                                }                                                

                                            {
                                            (o.o != ".s" && st == "" && j?.animate?.length > 0) && (
                                            <>
                                            <span className={`fas market-animate-${j.animate}`}></span>
                                            {this._removeAnimate(item.matchid, j)}
                                            </>
                                            )
                                            }
                                        </div>
                                    )
                                })
                                }
                            </div>

                            {
                            (s.hf && s.fh?.length > 0 && is_first_period) && (
                            <div className={`odd-group odd-group-${s.o.length}`}>
                                {
                                s.hf && s.fh?.length > 0 && is_first_period && s.fh.map((_o, oindex) => {
                                    
                                    let o = _o

                                    let st = "locked"
                                    let g = undefined
                                    let j = undefined
                                    
                                    let _g = item?.odds?.filter(x => x.oddsType == o.t && x.iss == false && x.isv == true && x.odds.find(y => y.isv == true && y.iss == false)).sort((a, b) => a.specialvalue - b.specialvalue)

                                    if (is_tie && o.t == 999999 && _g.length == 0) {
                                        _g = item?.odds?.filter(x => x.oddsType == 5518 && x.iss == false && x.isv == true && x.odds.find(y => y.isv == true && y.iss == false))
                                        let _s = schema.filter(x => x.i == 5518)
                                        if (_s?.length > 0) {
                                            _s = _s[0]
                                            o = _s?.o[oindex] ?? _o
                                        }
                                    }

                                    if (_g?.length > 0) {      

                                        g = _g[0]
                                        
                                        if (item.sportId == 1) {
                                            if (is_threshold && o.t == 5520) {
                                                _g = _g.filter(x => x.specialvalue == 0.5)
                                                if (_g.length > 0) {
                                                    g = _g[0]
                                                }
                                            } else if (o.t == 5520) {
                                                _g = _g.filter(x => x.specialvalue % 1 > 0)
                                                if (_g.length > 0) {
                                                    g = _g[0]
                                                }
                                            }
                                        }

                                        let k = g?.odds?.filter(x => x.outComeId == o.i)
                                       
                                        if (k?.length > 0) {
                                            j = k[0]
                                        }
                                    }                                    

                                    let odd = parseFloat(j?.odds.toFixed(4) ?? 1)
                                    if (odd > 1) st = Redux.getSelectionVisibilityStatus(item, g, j)                                    

                                    return (
                                        <div key={`${j?.Id ?? oindex+100000}-${oindex}`} className={classNames({
                                            "live-odd-button-cont": true
                                            })}>
                                            
                                            {
                                            o.o == ".s" && (
                                            <div className="odd-special">
                                            {g?.specialvalue ?? "-"}  
                                            </div>
                                            )
                                            }

                                            {
                                            (o.o != ".s" && st == "") && (
                                            <div className="active-odd">
                                            {odd.toFixed(2)}
                                            </div>
                                            )
                                            }

                                            {
                                            (o.o != ".s" && st == "locked") && (
                                            <div className="locked-odd">
                                            
                                            </div>                                          
                                            )
                                            }

                                            {
                                            (o.o != ".s" && st == "semi-locked") && (
                                            <div className="semi-locked-odd">
                                            {odd.toFixed(2)}
                                            </div>                                          
                                            )
                                            }  

                                        {
                                        (o.o != ".s" && st == "" && j?.animate?.length > 0) && (
                                        <>
                                        <span className={`fas market-animate-${j.animate}`}></span>
                                        {this._removeAnimate(item.matchid, j)}
                                        </>
                                        )
                                        }
                                    </div>
                                    )
                                })
                                }                                  
                            </div>
                            )
                            }

                            {   
                            (!s.hf && is_first_period) && (
                            <div className={`odd-group odd-group-${s.o.length}`}>
                                {
                                s.o.map((o, oindex) => {
                                    return (
                                        <div key={oindex + 200000} className={classNames({"live-odd-button-cont": true })}>
                                            <div className="locked-odd"></div>
                                        </div>
                                    )
                                })
                                }    
                            </div>
                            )
                            }                            
                            </>
                        )
                        }

                        {
                        s.y == "s" && (
                        <div className={classNames({"c-button-cont": true, "opacity-30": !item.IsStatAvailable})}>
                            <button disabled={!item.IsStatAvailable} onClick={() => { this.props.onShowStatistics(Redux.getStatisticsUrl(item.id)) }}>
                            <i className="fas fa-chart-bar" />
                            </button>
                        </div>
                        )
                        }

                        {
                        s.y == "a" && (
                        <button 
                            disabled={Boolean(item.extra_count < 1)}
                            className={classNames({"opacity-30": Boolean(item.extra_count < 1)})} 
                            onClick={() => { this.props.onDetail(item) }}
                        >
                        {
                        item.extra_count > 0 && (
                        <span>+{item.extra_count}</span>
                        )
                        }
                        </button>
                        )
                        }                                                       

                    </div>
                )
            })
            }
            </div>
            
        </div>
        );
    }
}

const msp = (state) => {
    return {
        config: state.config,
        member: state.member,
        _mid: state._mid,
        live_matches: state.live_matches
    }
}
  
export default connect(msp)(injectIntl(LiveMatchRow))
