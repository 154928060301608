import Axios from 'axios';
import constants from './constants'
import { store } from '../redux'
import { Redux } from '..';
import { injectIntl } from 'react-intl'
import CSocket from '../socket'

const defaultPrefs = {
    setUserId: true,
    setReqId: true,
    setMachineId: false,
    setLang: true,
    setOperatorId: false,
    setToken: false
}

class WebClient {
    
    constructor(defaultConfig) {

        defaultConfig = {
            baseURL: constants.base_url,
            headers: { 
                "Content-Type": "application/json"
            }
        };

        this.axiosInstance = Axios.create(defaultConfig);
    }

    get = (path) => {
        var realPromise = this.axiosInstance.get(path);
        return this.createProxyPromise(realPromise);
    }

    post = (path, data, prefs = defaultPrefs, showLoading = true) => {
        let states = store.getState()

        let d = {...data}

        if (prefs?.setLang) {
            d = {...d, lang: states.language}
        }

        if (prefs?.setMachineId) {
            d = {...d, machineId: states.config.machineId}
        }

        if (prefs?.setReqId) {
            d = {...d, reqId: constants.reqId}
        }

        if (prefs?.setUserId) {
            d = {...d, userId: states.member.userId}
        }        

        if (prefs?.setOperatorId) {
            d = {...d, operatorId: states.member.operatorId}
        }

        if (prefs?.setToken) {
            d = {...d, token: Math.random().toString(36).substring(2)}
        }
        
        if (prefs?.setServerId) {
            d = {...d, serverId: states.settings.serverid}
        }        

        var realPromise = this.axiosInstance.post(path, d);
        return this.createProxyPromise(realPromise, showLoading);
    }

    createProxyPromise(realPromise, showLoading) {
        if (showLoading) {
            Redux.showLoading();
        }
        
        var _self = this;
        var proxyPromise = new Promise(function (resolve, reject) {
            realPromise.then(function (data) {
                _self.responseHandler(data, resolve, reject);
            }).catch(error =>{
                _self.errorHandler(error, reject);
            })
        });
        return proxyPromise;
    }
    
    responseHandler=async(response, callback, reject) =>{
        Redux.hideLoading();
        console.log(response)
        callback(response.data);
    }

    errorHandler = async (error, reject) => {
        Redux.hideLoading();

        // if (error.response === undefined) {
        //     setError()
        //     return;
        // }

        if (error.response !== undefined && error.response.status == 401) {
            // toast.error("Oturum süreniz doldu veya sonlandı! Lütfen tekrar giriş yapmayı deneyin.")
            // logout()
        } else if (error.response !== undefined && error.response.status == 405) {
            // toast.error("İstek metodu hatalı!")
        } else if (error.response !== undefined && error.response.status == 403) {
            // toast.error(error.response.data.message)
        } else {
            // reject(error);
        }

        reject(error);
    }

}

function getUserData(type = 1, uid = null) {
    
    let userId = "";
    if (type == 2) userId = uid;
    else userId = store.getState().member.userId

    localStorage.setItem("userId", userId)

    let wc = new WebClient();
    wc.post(constants.uris.data, {userId}, {
        setReqId: true,
        setLang: true
    }).then((response) => {
        console.log(response)
        if (response.status) {

            if (type == 2) {
                const socket = new CSocket();
                socket.init();
            }

            Redux.setMemberData({userId, ...response})
            if (type == 2) getBingoUrl()
        } else {
            Redux.setReady(true)
            Redux.showToast("warning", response.error)
        }
    }).catch((error) => {
        Redux.setReady(true)
        Redux.showToast("error", "An error occurred")
    })
}

async function getBingoUrl() {
    let wc = new WebClient();
    wc.post(constants.uris.bngo, 
      {
        token: Math.random().toString(36).substr(2)
      }, 
      {
        setReqId: true,
        setMachineId: false,
        setUserId: true,
        setLang: true
      }).then((response) => {
        console.log(response)
        if (response.status) {

          let config = store.getState().config

          let video = 1;
          if (config.settings.secondScreen == "TombalaVideo") video = 0

          let gameUrl = `${response.content.gameurl}&terminal=1&video=${video}&sendData=1&origin=http://localhost:3000`

          Redux.setBingoUrl(gameUrl)

          if (config.settings.secondScreen == "TombalaVideo") {
              
            let a = response?.content?.gameurl?.split("?token");
            if (a?.length > 0) {
              let gameUrlForSecondScreen = `${a[0]}/#/secondScreen?token${a[1]}`
              localStorage.setItem("bingoUrl", gameUrlForSecondScreen)
            }
            
          }

          if (window?.ipcRenderer) {
            window.ipcRenderer.send("loginSucceeded", "_")
          }

        }
      })
  }  

export {
    WebClient,
    constants,
    getUserData
}