import React from 'react'
import { FormattedMessage } from "react-intl"

class Button extends React.Component {

    static defaultProps = {
        title: "Button",
        onClick: () => {},
        className: "default-button"
    }

    render() {
        const { title, onClick, className } = this.props
        return (
            <button onClick={onClick} 
                className={"bg-gradient-to-b from-gray-300 hover:from-gray-400 active:from-gray-100 to-gray-400 hover:to-gray-300 active:to-gray-100 px-10 py-2 text-2xl font-bold text-gray-800 border-2 border-gray-500"}>
                <FormattedMessage id={title} />
            </button>
        );
    }
}

export default Button;
