import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from "react-intl"
import * as Redux from '../../../components/redux' 
import TerminalLogin from './login-form-terminal'
import FillialeLogin from './login-form-filliale'

class Login extends React.Component {

  render() {
    const { config } = this.props
    // console.log(config)
    return (
      <div className="login-container">
        <div onClick={() => { this.copyToClipboard(config.machineId) }} className="machine-id-container">
          {config.machineId}
        </div>

        {
        config.settings.userType == "T" ?
        <TerminalLogin />
        :
        <FillialeLogin />
        }

      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config,
      member: state.member
  }
}

export default connect(msp)(injectIntl(Login)) 
