import React from 'react'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { Redux, WebClient, constants, UpDown } from '../../../../components'

let scrollM = 0

class SubItemList extends React.Component {

  render() {

    const { subItems, activeSubServiceId } = this.props

    let w = window.innerWidth;
    let ww = (w * 80) / 100
    let h = (ww / 3) * 0.75;

    return (      
        <>        
            {
            subItems.length > 1 && (
              <div className="r-w-arrows-sub-c">
              
                  {
                  subItems.length > 3 && (
                    <div className="h-arrow">
                        <button className="h-arrow-in">
                            <i className="fas fa-chevron-left" />
                        </button>
                    </div>
                  )
                  }              

                <div className={`${subItems.length > 3 ? "sub-items-w-a" : "sub-items"}`}>

                  <FlatList
                    style={{ width: ww, height: "100%" }}
                    contentContainerStyle={{ width: "100%", height: "100%" }}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    data={subItems}
                    horizontal={true}
                    renderItem={({item, index}) => {
                        return (
                        <div key={index} 
                            onClick={() => { this.props.onItemClick(item) }}
                            className="sub-item-c" style={{ width: subItems.length > 3 ? ((ww - 172) / 3) : (ww / 3) }}>
                            <button className={`sub-item-c-in ${activeSubServiceId == item.serviceId && ("active")}`}>
                                {item.title}
                            </button>
                        </div>
                        )
                    }}
                    />

                </div>

                  {
                  subItems.length > 3 && (
                    <div className="h-arrow">
                        <button className="h-arrow-in">
                            <i className="fas fa-chevron-right" />
                        </button>
                    </div>
                  )
                  }                  

              </div>
            )
            }
        </>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(SubItemList)
