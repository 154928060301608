import React from 'react'
import { connect } from 'react-redux'
import { Redux, WebClient, constants, UpDown, CFormatDate, CButton } from '../../components';
import { FormattedMessage, injectIntl, FormattedDate, FormattedTime } from 'react-intl'
import classNames from 'classnames'
import Swal from 'sweetalert2/src/sweetalert2.js'
import moment from 'moment'
import _ from 'lodash'
import MatchList from './live-match-list'
import SocketUtil from '../../components/socket'

let z = 0;
let l = 0;

class LiveMatches extends React.Component {

  constructor(props) {
    super(props)
    this.webClient = new WebClient();
    this.state = {
      loading: true,
      gameUrl: "",
      start: new Date(),
      end: moment(new Date(), "DD-MM-YYYY").add(45, 'days'),
      activeSport: {sportId: 1, sportName: props.intl.formatMessage({id: "Football"})},
      schema: [],
      listReady: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.startAction()
    }, 2000)
  }

  async startAction() {

    let a = await localStorage.getItem("userId")

    if (a != null) {
      Redux.setMemberData({userId: a})
      this._getMatches(this.state.activeSport)
    }
    console.log("user id", a)

  }

  _getMatches(e) {

    this.setState({matches: [], listReady: false, activeSport: e})

    Redux.setLiveActiveSport(e.sportId)
    // let odds = Redux.getLiveOddsBySportId(e.sportId)
    // console.log("live odds", odds)

    let obj = window?.livesportsnew ?? window?.livesports;
    let z = obj[1]
    let odds = z?.settings ? JSON.parse(z.settings) : []

    const typenames = Redux.getTypeNames(e.sportId);
    const selected_tabs = Redux.getSelectedLiveTabsForSecondScreen(e.sportId)

    const { start, end } = this.state

    let d = {
      startDate: moment(start).format("YYYY-MM-DD HH:mm"),
      endDate: moment(end).format("YYYY-MM-DD HH:mm"),
      sportId: e.sportId,
      odds,
      limit: 400,
      sportCountLive: 1
    }

    console.log(d)

    this.webClient.post(constants.uris.live, d, 
      {
        setLang: true,
        setOperatorId: true,
      }, false).then((response) => {
        if (response.status) {         

          let first = response.matches[0]

          let z = response.matches.filter(x => x.betstatus == "started")

          if (z.length > 0) {
            z = _.orderBy(z, ['matchtime'], ['asc']);
            first = z[0]
          }

          const first_odds = first?.odds ?? []

          this._generateTitleSchema(odds[0].odds, typenames, first_odds, selected_tabs, response.matches)

          Redux.setMid(null)
          Redux.setLiveMatches(response.matches)
          this.setState({sports: response.sports, typenames: typenames, ready: true, listReady: true, totalRows: response.totalRows})

          const sc = new SocketUtil();
          sc.init()

        }

      })
  }  

  _generateTitleSchema(odds, typenames, match_odds, selected_tabs, matches) {

    let schema = []
    let markets = []

    z = 1;
    l = 1;
    schema.push({y: "d", o: [], order: 0})
    schema.push({y: "t", o: [], order: 0})

    odds.map(el => {

      let odd_type = undefined

      for (let q = 0; q < matches.length; q++) {
        let qz = matches[q]
        let qq = qz?.odds?.filter(x => x.oddstype == el.oid)
        if (qq.length > 0) {
          odd_type = qq[0]
          break;
        }
      }

      if (odd_type !== undefined) {

        let has_special = false

        matches.map((e) => {
          e.odds.filter(x => x.oddstype == el.oid).map((f) => {
            if (!f?.special?.startsWith('*')) has_special = true
          })
        })
        
        let first_half = []
  
        let has_first_half = el?.ht ? true : false 
        let is_first_half = Boolean(odds.filter(x => x.ht == el.oid)?.length > 0)

        if (!is_first_half) {
          // console.log("is_first_half", is_first_half)
  
          if (has_first_half) {
            // console.log(match_odds.filter(x => x.oddtypeid == el.ht))
            let first_half_odds = undefined
            for (let q = 0; q < matches.length; q++) {
              let qq = matches[q].odds?.filter(x => x.oddstype == el.ht)
              if (qq.length > 0) {
                first_half_odds = qq[0]
                break;
              }
            }            
  
            if (first_half_odds) {
  
              if (has_special) {
                first_half.push({o: ".s", i: first_half_odds.odds[0].outComeId, t: el.ht})
              }            
  
              first_half_odds.odds.map((se) => {
                first_half.push({o: se.outCome.replace(" ({0})", "").replace(" ({h})", "").replace("{sw}", ""), i: se.outComeId, t: el.ht})
              })            
            }
  
          }
  
  
          let o = []
  
          // let has_special = !odd_type?.special?.startsWith('*') ?? false
  
          if (has_special) {
            o.push({o: ".s", i: odd_type.odds[0].outComeId, t: odd_type.odds[0].oddsType})
            // if (is_column_selected) z++;
          }
  
  
          odd_type.odds.map((se) => {

            // console.log("se", se)

            o.push({o: se.outCome.replace(" ({0})", "").replace(" ({h})", "").replace("{sw}", ""), i: se.outComeId, t: el.oid})
            // if (is_column_selected) z++;
          })
  
          // schema.push({y: "o", t: odd_type.typeName, o, s: has_special, i: el.oid, fh: first_half, hf: has_first_half, ics: is_column_selected})
          markets.push({y: "o", t: odd_type.typeName.replace("{sw}", ""), o, s: has_special, i: el.oid, fh: first_half, hf: has_first_half, ics: false})
          // l++;
        }

      }

    });


    let column = 0
    let filtered = markets.filter(x => selected_tabs.includes(x.i))

    filtered.map((f) => {

      let _o = selected_tabs.indexOf(f.i) == -1 ? 1000 : (selected_tabs.indexOf(f.i) * 10)

      f.ics = true
      f.order = _o
      schema.push(f)
      column++;
      l++;
      z = z + f.o.length
    })

    filtered = markets.filter(x => !selected_tabs.includes(x.i))
    console.log(filtered)

    for(let i = 0; i < filtered.length; i++) {
      let _z = Boolean(column < 5)
      let _o = selected_tabs.indexOf(filtered[i].i) == -1 ? 1000 : (selected_tabs.indexOf(filtered[i].i) * 10)
      filtered[i].ics = _z ? true : false
      filtered[i].order = _o
      schema.push(filtered[i])
      if (_z) {
        l++;
        column++;
        z = z + filtered[i].o.length
      }
    }

    // schema.push({y: "s", o: [], order: 10000})
    // schema.push({y: "a", o: [], order: 10000}) 

    schema = _.orderBy(schema, ['order'],['asc']);
    console.log(schema)

    let stabs = schema.filter(x => x.y == "o" && x.ics == true).reduce((a, o) => (a.push(o.i), a), [])
    Redux.updateSelectedLiveTabsForSecondScreen(this.state.activeSport.sportId, stabs)

    this.setState({schema})

  } 

  _getHalfTime() {
    if (this.state.activeSport.sportId == 1) {
      return 45
    }
  }  

  render() {

    const { loading, gameUrl, schema, activeSport, listReady, typenames } = this.state

    return (
      <div className="bg-cgray-600" style={{ width: "100vw", height: "100vh"}}>

          <div className="match-title-cont">
          {
          schema.map((s, sindex) => {

              if (s.y == "o" && !s.ics) return null

              return (
                  <div key={sindex} data-box={z} data-col={l} className={`t-${s.y} s-t-o-${s.o?.length ?? 0}`}>

                      {
                      s.y == "d" && (
                          <p></p>
                      )
                      }

                      {
                      s.y == "t" && (
                          <p>{activeSport?.sportName}</p>
                      )
                      }        

                      {
                      (s.y == "o" && s.ics) && (
                          <div className="relative w-full">
                          <span className="text-center w-full">{s.t?.replace("{pw}", "").split("(")[0]}</span>
                          <div className="t-o-c">
                              {
                              s.o.map((o, oindex) => {
                                  return (
                                      <div className="t-o-i" key={oindex}>{o.o == ".s" ? "" : o.o}</div>
                                  )
                              })
                              }
                          </div>
                          </div>
                      )
                      }

                      {
                      s.y == "s" && (
                          <div>

                          </div>
                      )
                      }

                      {
                      s.y == "a" && (
                          <div>

                          </div>
                      )
                      }                                                       

                  </div>
              )
          })
          }
          </div>

          <div ref={(ref) => { this.matchListDiv = ref }} className="live-in-page-middle-right">
            
            {
            listReady && (
            <MatchList 
              typenames={typenames}
              schema={schema}
              z={z}
              l={l}
              activeSport={activeSport} 
              halfTime={this._getHalfTime()}
              onDetail={(e) => {  }}
              onShowStatistics={(e) => {  }} 
            />
            )
            }

          </div>             

      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(injectIntl(LiveMatches))