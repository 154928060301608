import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { Redux, WebClient, constants } from '../../../../components'
import assets from '../../../../components/utils/assets'
import CButton from './button'
import moment from 'moment'
import classNames from 'classnames'
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized'
import { addCoupon, removeOdd, addOdd, oddExists } from '../../../../components/utils/coupon'
import "../../../../../assets/common/grids.css"

let tournament_title = ""
let showTitle = true;

class MatchList extends React.Component {

    static defaultProps = {
        showTitle: false,
        index: 0
    }

    componentDidUpdate() {
        tournament_title = ""
    }

    render() {
        const { matches, activeSport, coupon, intl } = this.props
        
        let a = matches[0]
        let schema = []

        let z = 3;
        let l = 3 + a?.odds?.length ?? 0

        schema.push({y: "d", o: []})
        schema.push({y: "t", o: []})

        a?.odds?.forEach(el => {
            let o = []
            let f = schema.filter(x => x.t == el.typeName)

            if (f?.length == 0) {

                el.odds.forEach((se) => {
                    o.push(se.outCome)
                    z++;
                })

                schema.push({y: "o", t: el.typeName, o, s: el.specialvalue})
            }

        });

        schema.push({y: "s", o: []})
        schema.push({y: "a", o: []})

        return (
            <>
            {
              matches.map((item, index) => {
                if (tournament_title === item.tournamentName) showTitle = false;
                else showTitle = true
                tournament_title = item.tournamentName

                let oe = Boolean(index%2 == 0) ? "odd" : "even"

                return (
                    <div key={`10000${index}`}>
                    
                    {
                    showTitle && (
                        <div className="match-title-cont">
                        {
                        schema.map((s, sindex) => {
                            return (
                                <div key={`2000${index}${sindex}`} data-box={z} data-col={l} className={`t-${s.y} t-o-${s.o?.length ?? 0}`}>

                                    {
                                    s.y == "d" && (
                                        <img src={item.categoryFlag} />
                                    )
                                    }

                                    {
                                    s.y == "t" && (
                                        item.tournamentName
                                    )
                                    }        

                                    {
                                    s.y == "o" && (
                                        <>
                                        <span>{s.t} {s.s?.length > 0 && (`(${s.s})`)}</span>
                                        <div className="t-o-c">
                                            {
                                            s.o.map((o, oindex) => {
                                                return (
                                                    <div key={`70000${index}${sindex}${oindex}`} className="t-o-i" key={oindex}>{o}</div>
                                                )
                                            })
                                            }
                                        </div>
                                        </>
                                    )
                                    }

                                    {
                                    s.y == "s" && (
                                        <div>

                                        </div>
                                    )
                                    }

                                    {
                                    s.y == "a" && (
                                        <div>

                                        </div>
                                    )
                                    }                                                       

                                </div>
                            )
                        })
                        }
                        </div>
                    )
                    }

                    <div className="match-row-cont"> 
                        {
                        schema.map((s, sindex) => {
                            return (
                                <div key={`400000${index}${sindex}`} data-box={z} data-col={l} className={`m-${s.y} i-c-${oe}`}>

                                    {
                                    s.y == "d" && (
                                        <>
                                        <span>{moment(item.matchDate).format("DD/MM")}</span>
                                        <span>{moment(item.matchDate).format("HH:mm")}</span>
                                        </>
                                    )
                                    }

                                    {
                                    s.y == "t" && (
                                        <div className="w-full flex flex-row items-center justify-between px-8">
                                            <span className="m-t-h">{item.homeTeam}</span>
                                            <span className="m-t-s">-:-</span>
                                            <span className="m-t-h">{item.awayTeam}</span>
                                        </div>
                                    )
                                    }        

                                    {
                                    s.y == "o" && (
                                        <>
                                        <div className="m-o-c">
                                            {
                                            s.o.map((o, oindex) => {

                                                let f = sindex - 2
                                                let g = item?.odds[f]
                                                let odd = g?.odds[oindex]

                                                let unique = `${item.id}-${odd?.Id}-${odd?.oddsType}-${odd?.outComeId}-${odd?.special}`
                                                let cp = coupon.filter(x => x.matchid == item.id)
                                                let is_added = false
                                                
                                                if (cp.length > 0) {
                                                    cp = cp[0]
                                                    is_added = Boolean(cp.odd.filter(x => x.unique == unique).length > 0)
                                                }

                                                return (
                                                    <div key={`5000${index}${sindex}${oindex}`} className="m-o-i">
                                                        <div className="c-button-cont">
                                                            {
                                                            (odd?.isv && odd?.odds > 1 && g?.isv) ?
                                                            <button onClick={() => { Redux.addOrRemoveOdd(item, odd, intl) }} className="odd-button"
                                                            className={classNames({
                                                                [`odd-button`]: !is_added,
                                                                'odd-button-in-coupon': is_added
                                                            })}>
                                                                {odd?.odds.toFixed(2) ?? "-"}
                                                            </button>
                                                            :
                                                            (
                                                            (odd?.iss && g?.iss) ?
                                                            <button className="odd-button opacity-30">
                                                                {odd?.odds.toFixed(2) ?? "-"}
                                                            </button>
                                                            :
                                                            <button className="odd-button" disabled={true}>-</button>
                                                            )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                        </>
                                    )
                                    }

                                    {
                                    s.y == "s" && (
                                    <div className="c-button-cont">
                                        <button disabled={!Boolean(item?.IsStatAvailable)} onClick={() => { this.props.onShowStatistics(Redux.getStatisticsUrl(item.id)) }}>
                                        <i className="fas fa-chart-bar" />
                                        </button>
                                    </div>
                                    )
                                    }

                                    {
                                    s.y == "a" && (
                                    <button onClick={() => { this.props.onDetail(item) }}>
                                    +{item.oddCount}
                                    </button>
                                    )
                                    }                                                       

                                </div>
                            )
                        })
                        }
                    </div>            
                    </div>
                )
              })
            } 
            </>          
        );
    }
}

const msp = (state) => {
    return {
        config: state.config,
        member: state.member,
        smatches: state.smatches,
        coupon: state.coupon
    }
  }
  
  export default connect(msp)(injectIntl(MatchList))
