import makeUniq from "./oddUnique";
import nameReplacer from "./name-replacer";
import nameReplacer2 from "./name-replacer2";
// const makeUniq = (oddtype, outcome, special) => {
//   return [11,8,38,5,56,46,284,285,208].indexOf(oddtype) !== -1 ?
//           `${oddtype}_${outcome}_${special || 0}` :
//           `${oddtype}_${outcome}`
// }

const oddShortJson = (
  odd,
  oddtype,
  special,
  isLive,
  match,
  oddTypeObj,
  miss,
  misv,
  market
) => {
  let outcome = parseInt(odd.outcomeid) || parseInt(odd.outComeId) || 0;
  let outcomeObj =
    oddTypeObj && oddTypeObj.odds
      ? oddTypeObj.odds.find(a => a.outcomeid == odd.outcomeid)
      : "";
  special = odd.special ? odd.special : special;
  return {
    active: market.active == 0 ? 0 : parseInt(odd.active),
    uniq: makeUniq(oddtype, outcome, special, isLive),
    outcomeid: outcome,
    iss: match.iss
      ? true
      : miss
      ? true
      : odd.hasOwnProperty("iss")
      ? odd.iss
      : odd.active == 0,
    isv: !match.isv
      ? false
      : !misv
      ? false
      : odd.hasOwnProperty("isv")
      ? odd.isv
      : odd.active == 1,
    // noutcomeid: odd.noutcomeid, // we dont need it
    odd: parseFloat(odd.odd || odd.odds) || 0,
    pos: 0,
    outcome2: nameReplacer(
      outcomeObj && outcomeObj.outcome
        ? outcomeObj.outcome
        : odd.outCome || odd.outcome,
      match
    ),
    outcome: nameReplacer2(
      outcomeObj && outcomeObj.outcome
        ? outcomeObj.outcome
        : odd.outCome || odd.outcome,
      match
    ),
    ttype: isLive ? "L" : "N",
    mintip: 1,
    special,
    specialvalue:
      oddtype == 5503
        ? special.split("*")[0]
          ? special.split("*")[0]
          : "0"
        : special.split("*")[0],
    oddstatus: odd.hasOwnProperty("oddtypestatus") ? odd.oddtypestatus : false,
    status: odd.hasOwnProperty("oddfieldstatus") ? odd.oddfieldstatus : false,
    kmis:
      odd.kombiMinStake == undefined
        ? parseFloat(odd.minStake)
        : parseFloat(odd.kombiMinStake),
    kmas:
      odd.kombiMaxStake == undefined
        ? parseFloat(odd.maxStake)
        : parseFloat(odd.kombiMaxStake),
    kmay:
      odd.kombiMaxPay == undefined
        ? parseFloat(odd.maxPay)
        : parseFloat(odd.kombiMaxPay),
    smis:
      odd.singleMinStake == undefined
        ? parseFloat(odd.minStake)
        : parseFloat(odd.singleMinStake),
    smas:
      odd.singleMaxStake == undefined
        ? parseFloat(odd.maxStake)
        : parseFloat(odd.singleMaxStake),
    smay:
      odd.singleMaxPay == undefined
        ? parseFloat(odd.maxPay)
        : parseFloat(odd.singleMaxPay)
  };
};

const oddParseJson = (n, isLive = true, match, detail = false) => {
  let oddtype = parseInt(n.oddstype || n.oddsType) || 0;
  let special =
    n.special || (n.special && n.special == 0) ? n.special || null : "";
  let specialvalue =
    n.specialvalue || (n.specialvalue && n.specialvalue == 0)
      ? n.specialvalue || null
      : "";
//   let oddTypeObj =
//     match &&
//     match.sportId &&
//     oddtype &&
//     box &&
//     box.sports &&
//     box.sports.odds &&
//     box.sports.odds[match.sportId] &&
//     box.sports.odds[match.sportId].find(a => a.oddstype == oddtype)
//       ? box.sports.odds[match.sportId].find(a => a.oddstype == oddtype)
//       : "";

    let oddTypeObj = ""

  return {
    csh: n.hasOwnProperty("csh") ? n.csh : false,
    active:
      match.active == 0 || match.matchstatus == "stopped"
        ? 0
        : parseInt(n.active),
    oddsid: parseInt(n.oddsid) || 0,
    oddstype: oddtype,
    // noddTypeId: n.noddTypeId || "", //we dont need it
    // typename: n.typename || n.typeName,
    // nicename: nameReplacer(n.typename || n.typeName, match),
    typename:
      oddTypeObj && oddTypeObj.typename && isLive && !detail
        ? nameReplacer(
            oddTypeObj.typename || n.typename || n.typeName,
            match,
            n
          )
        : nameReplacer(n.typename || n.typeName, match, n),
    nicename:
      oddTypeObj && oddTypeObj.typename && isLive && !detail
        ? nameReplacer(
            oddTypeObj.typename || n.typename || n.typeName,
            match,
            n
          )
        : nameReplacer(n.typename || n.typeName, match, n),
    oldSpecial: "",
    special,
    specialvalue,
    sq: n.sq,
    iss: match.iss ? true : n.hasOwnProperty("iss") ? n.iss : n.active == 0,
    isv: !match.isv ? false : n.hasOwnProperty("isv") ? n.isv : n.active == 1,
    mostbalanced: parseInt(n.mostbalanced) || 0,
    odds: n.odds.map(z =>
      oddShortJson(
        z,
        oddtype,
        special,
        isLive,
        match,
        oddTypeObj,
        match.iss ? true : n.hasOwnProperty("iss") ? n.iss : n.active == 0,
        !match.isv ? false : n.hasOwnProperty("isv") ? n.isv : n.active == 1,
        n
      )
    )
  };
};

export { oddParseJson };
