import React from 'react'
import { connect } from 'react-redux'
import Login from './auth'
import Main from './main'
import Spinner from '../../components/spinner'
import { WebClient, constants, getUserData } from '../../components/utils/webclient'
import * as Redux from '../../components/redux'
import "../../../assets/themes/default/app.css"
import "@sweetalert2/themes/dark/dark.css"

import LiveMatches from '../live-matches'

class MainRoot extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
    this.startAction()
  }

  async startAction() {
    let s = await localStorage.getItem("uname");
    // console.log("localstorage ------------------>", s)
    if (s !== undefined && s !== null) {
      this._login(s)
    } else {
      Redux.setReady(true)
    }
  }

  _login(userName) {
    let wc = new WebClient();
    wc.post(constants.uris.logn, {
        userName,
        passwordType: "normal",
    }, {
        setReqId: true,
        setMachineId: true,
        setLang: true
    }).then((response) => {
        // console.log("login", response)
        if (response.status) {
            // window.ipcRenderer.send("saveMember", b)
            // Redux.setMemberData(response)
            Redux.setTerminalPass(response.terminalPass)
            localStorage.setItem("uname", userName)
            getUserData(2, response.userId)
        } else {
          Redux.setReady(true)
          Redux.showToast("warning", response.error)
        }
    }).catch((error) => {
        // console.log({...error})
        Redux.showToast("error", "An error occurred")
        Redux.setReady(true)
    })
  }  

  render() {
    const { member, loading, ready } = this.props

    if (!ready) {
      return (
      <div className="w-screen h-screen" style={{ backgroundColor: '#1c1e21' }}>
        <div className="loading-container">
          <Spinner />
        </div>        
      </div>
      )
    }

    return (
      <div className="w-screen h-screen">
      {
      member == null ?
      <Login />
      :
      <Main />
      }

      {
      loading && (
      <div className="loading-container">
        <Spinner />
      </div>
      )
      }

      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config,
      member: state.member,
      loading: state.loading,
      ready: state.ready
  }
}

export default connect(msp)(MainRoot)
