import React from 'react';
import { NavigationContainer } from '@react-navigation/native'
import { Provider } from 'react-redux'
import { AppRegistry, StyleSheet, Text, View, Dimensions, FlatList, TouchableOpacity } from 'react-native';
import { navigationRef } from './project/components/nav/root-nav'
import { store } from './project/components/redux'
import reportWebVitals from './reportWebVitals';
import Project from './App';
import { constants, WebClient } from './project/components/utils/webclient'

import { MainIntlProviderWrapper } from './project/components/intl-providers/main-intl-provider'

import { ToastContainer } from "react-toastify"

import Tombala from './project/windows/tombala'
import LiveMatches from './project/windows/live-matches'

import "./assets/fonts/fonts.css"
import "./assets/fonts/icomoon.css"
import "@fortawesome/fontawesome-free/css/solid.min.css"
import "@fortawesome/fontawesome-free/css/regular.min.css"
import "@fortawesome/fontawesome-free/css/brands.min.css"
import "@fortawesome/fontawesome-free/css/fontawesome.min.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "react-toastify/dist/ReactToastify.css"
import { Redux } from './project/components';

// const electron = window.require('electron')

// import "./assets/app.css"

// const { width, height } = Dimensions.get("window");

TouchableOpacity.defaultProps = TouchableOpacity.defaultProps || {};
TouchableOpacity.defaultProps.activeOpacity = 0.95;

let screenType = "Main" 

class App extends React.Component {

  state = {
    list: [],
    loaded: false
  }

  componentDidMount() {

    this._getSettings();

    // console.log(electron.remote)

    // if (window.location?.search?.length > 0) {
    //   screenType = window.location.search.replace("?", "")
    // }

    // this.setConfig()
  }
  
  _getSettings() {
    let cc = new WebClient();
    cc.get("https://data.apiekol.com/v1/settings/get?domain=ekol19.com")
      .then((response) => {
        //console.log(response)

        if (response?.generalJs) {
          //console.log("test")
          const script = document.createElement("script");
          script.src = constants.base_url + "/" + response.generalJs;
          script.async = true;
          document.body.appendChild(script);
        }

        Redux.setSettings(response)

        this.setState({loaded: true})
      })
      .catch((error) => {
        //console.error(error)
        this.setState({loaded: true})
      })
  }

  // async setConfig() {

  //   const { 
  //     windowType, 
  //     externalDisplay, 
  //     displayCount, 
  //     hasExternalDisplay, 
  //     printerCount, 
  //     printers,
  //     settings,
  //     machineId,
  //     configType
  //   } = await electron.remote.getGlobal('shareObject')

  //   let sett = {
  //     mainScreen: settings.mainScreen,
  //     secondScreen: settings.secondScreen,
  //     hasExternalDisplay: settings.hasExternalDisplay,
  //     printer: settings.printer,
  //     onlineCheck: settings.onlineCheck,
  //     userType: settings.userType,
  //     version: settings.version
  //   }

  //   let config = {
  //     windowType,
  //     externalDisplay,
  //     displayCount,
  //     hasExternalDisplay,
  //     printerCount,
  //     printers,
  //     settings: sett,
  //     machineId,
  //     configType: configType ?? "mainConfig"
  //   }

  //   console.log(config)

  //   await store.dispatch({
  //       type: "SET_CONFIG",
  //       payload: config
  //   })

  //   // this.setState({loaded: true})
  //   this._getSettings()
  // }

  render() {

    const { loaded } = this.state

    if (!loaded) return null

    return (
      <NavigationContainer ref={navigationRef}>
        <Provider store={store}>
          <View style={{ width: "100%", height: "100%" }}>

            {
            screenType == "Main" && (
              <Project />
            )
            }

            {
            screenType == "TombalaVideo" && (
              <MainIntlProviderWrapper>
                <Tombala />
              </MainIntlProviderWrapper>
            )
            }

            {
            screenType == "LiveMatches" && (
              <MainIntlProviderWrapper>
                <LiveMatches />
              </MainIntlProviderWrapper>
            )
            }       

            <ToastContainer autoClose={2000} />
          
          </View>
        </Provider>
      </NavigationContainer>
    )
  }
}

AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', { rootTag: document.getElementById('root') });

reportWebVitals();

