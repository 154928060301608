import React from 'react'
import { FlatList } from 'react-native'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Redux, UpDown, OddsFilter } from '../../../../components'
import Coupon from '../coupon'
import ClassicStats from './classic-stats'
import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import MatchMarketGroup from './match-market-group'

let scrollM = 0
let h = 300

let exclude_markets = [5504]

class CStatisticIframe extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showStatistics: true,
            item: props.match,
            activeTab: 0
        }
        this.oddListDiv = React.createRef()
    } 

    shouldComponentUpdate(nextProps, nextState) {

        if (nextState?.activeTab != this.state.activeTab) {
            return true
        }

        // if (nextProps?._mid == this.state.item?.matchid) {
        //     return true
        // }

        return false;
    }  

    _setActiveTab(activeTab, i) {
        this.setState({activeTab})
        this.l.scrollToIndex({animated: false, index: "" + i > 0 ? i - 1 : i})
    }

    render() {
        const { showStatistics, activeTab } = this.state
        const { data, match, coupon, intl, member, activeSport } = this.props

        let oddsFilter = OddsFilter[activeSport.sportId]

        let group = []
        match.odds.map((e, i) => {
            let _a = group.find(x => x.oddsType == e.oddsType)
            let _b = e.odds.filter(x => x.odds > 1 && x.isv == true && x.iss == false)
            if (_a && _b.length > 0) {
                _a.odds.push(..._b)
            } else if (!_a && _b.length > 0) {
                group.push({
                    oddsType: e.oddsType,
                    typeName: e.typeName, 
                    odds: _b
                })
            }
        })

        group.filter(x => !exclude_markets.includes(x.oddsType)).map((g) => {
            if (g.odds.find(x => x.special.split("*")[0] != "")) {
                g.odds = _.orderBy(g.odds, ['special'],['asc']);
            }
        })

        oddsFilter.map((o, index) => {

            let show = Boolean(o.id == 0)
            let _c = 0;

            if (Boolean(o.id != 0)) {
                
                let ids = o.ids
                let g = group.filter(x => ids.includes(x.oddsType)) ?? []
                _c = g.filter(x => x.odds.filter(x => x.odds > 1 && x.isv == true && x.iss == false).length > 0).length
                show = Boolean(_c > 0)

            } else {
                _c = group.filter(x => x.odds.filter(x => x.odds > 1 && x.isv == true && x.iss == false).length > 0).length
            }

            o.show = show;
            o.count = _c
        })

        const filtered = oddsFilter.filter(x => x.show == true)

        let url = ""
        if (match.matchstatus != "not_started") url = Redux.getLiveStatisticsUrl(match)
        
        return (
            <div className="coupon-overlay">

            <div className="live-detail-left">

                <div className="w-full p-2">

                    <div className="live-detail-title">

                        <div className="live-detail-title-col">
                            <img src={match.categoryFlag} />
                            <span>{match.categoryName}</span>
                        </div>

                        <span className="live-detail-title-col">{match.Home} - {match.Away}</span>

                        <span className="live-detail-title-col">{match.tournamentName}</span>

                    </div>

                </div>


                <div className="live-matches-left-b hide-scrollbar">

                {
                (url?.url && match.matchstatus != "not_started") && (
                    <div className="live-statistic-cont">

                        <div onClick={() => { this.setState({showStatistics: !showStatistics}) }} className="live-statistic-title">
                            <span><FormattedMessage id="live_statistics" /></span>
                            <i className={`fas fa-chevron-${showStatistics ? "up" : "down"}`} />
                        </div>

                        {
                        showStatistics && (
                        <div className={classNames({"live-statistics-area": Boolean(url.type == 1), "live-statistics-area-2": Boolean(url.type == 2)})}>

                            <iframe id="ApiId" src={url.url} width="100%" height="100%" scrolling="no" frameborder="0"></iframe>

                        </div>
                        )
                        }

                    </div>
                    )
                }                

                {
                match.matchstatus != "not_started" && (
                <ClassicStats match={match} />
                )
                }

                <div className="w-full px-2">

                    <div className="market-tabs">
                        <div className="market-tabs-favorite"><i className="fas fa-star" /></div>
                        <div className="market-tabs-middle">

                        <FlatList
                            ref={(ref) => { this.l = ref }}
                            data={filtered}
                            showsHorizontalScrollIndicator={false}
                            showsVerticalScrollIndicator={false}
                            horizontal={true}
                            renderItem={({item, index}) => {
                                return (
                                    <div key={`tab-${index}`} 
                                        onClick={() => { this._setActiveTab(item.id, index) }}
                                        className={
                                        classNames("market-tab-item", 
                                        {
                                            "selected": Boolean(activeTab == item.id)
                                        })}>
                                        {item.title} ({item.count})
                                    </div>
                                )
                            }}
                        />
                        </div>

                    </div>
                    
                    {
                    activeTab == 0 ?
                    <div className="market-groups">
                        {
                        group.map((g, index) => {
                            return (
                                <MatchMarketGroup key={`${index}-${g.oddsType}`} g={g} />
                            )
                        })
                        }
                    </div>                    
                    :
                    <div className="market-groups">
                        {
                        group.filter(x => oddsFilter[activeTab]?.ids?.includes(x.oddsType)).map((g, index) => {
                            return (
                                <MatchMarketGroup key={`${index}-${g.oddsType}`} g={g} />
                            )
                        })
                        }
                    </div>
                    }

                    

                </div>


                </div>

                <UpDown size="md" mb="mb-0" upClick={() => {  }} downClick={() => {  }} />

            </div>

            <div className="coupon-container">
                <Coupon onClose={() => { this.props.onClose() }} />
            </div>            

            </div>
        );
    }
}

const msp = (state) => {
  return {
      config: state.config,
      coupon: state.coupon,
      member: state.member,
      _mid: state._mid
  }
}

export default connect(msp)(injectIntl(CStatisticIframe))
