import classNames from 'classnames';
import React from 'react'
import { FormattedMessage } from 'react-intl'

class CButton extends React.Component {

    static defaultProps = {
        type: 1,
        icon: false,
        active: false,
        iconName: "",
        width: "w-full",
        height: "h-common",
        uppercase: "",
        text: "",
        flagSee: 1,
        countryFlag: "",
        count: 0,
        onClick: () => {},
        translate: false,
        isSport: false,
        sportId: 1,
        mr: ""
    }

    render() {

        const { type, text, active, count, onClick, width, icon, iconName, height, countryFlag, flagSee, uppercase, translate, isSport, sportId, mr } = this.props

        return (
            <div className={classNames(
                {
                    "c-button-cont": true,
                    [`${width}`]: Boolean(width?.length > 0),
                    [`${height}`]: Boolean(type == 2),
                    "active": active,
                    "uppercase": Boolean(uppercase?.length > 0),
                    [`${mr}`]: Boolean(mr?.length > 0)
                })}>

                {
                type == 1 && (
                <button onClick={onClick}>

                    {
                    isSport && (
                    <span className="button-sport-icon"><i className={`icon-sport-${sportId}`} /></span>
                    )
                    }

                    {
                    icon ?
                    <i className={`fas ${iconName}`} />
                    :
                    (
                    translate ? <FormattedMessage id={text} />
                    : 
                    text
                    )
                    }

                    {
                    count > 0 && (
                        <span className="count ml-m">({count})</span>
                    )
                    }

                </button>
                )
                }

                {
                type == 2 && (
                <button className="c-button-type2" onClick={onClick}>

                    <div className="img-c">
                        <img src={countryFlag} style={{ width: "80%", height: "auto" }} />
                    </div>

                    {
                    translate ?
                    <FormattedMessage id={text} />
                    :
                    text
                    }

                    <span className="count">{count}</span>

                </button>
                )
                }
                
                {
                type == 3 && (
                <button className={`c-button-type3 ${active ? "active" : ""}`} onClick={onClick}>

                    <div className="img-c">
                        {/* <img src={countryFlag} style={{ width: "80%", height: "auto" }} /> */}
                        <i className="fas fa-chevron-right" />
                    </div>

                    <div className="c-button-type3-title">
                    {
                    translate ?
                    <FormattedMessage id={text} />
                    :
                    text
                    }
                    </div>

                    <span className="count">{count}</span>

                </button>
                )
                }                                

            </div>
        );
    }
}

export default CButton
