import React from 'react'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { Redux, WebClient, constants, UpDown, CFormatDate, CButton } from '../../../../components'
import { FormattedMessage, injectIntl, FormattedDate, FormattedTime } from 'react-intl'
import classNames from 'classnames'
import Swal from 'sweetalert2/src/sweetalert2.js'
import moment from 'moment'

import AdminAccounts from './accounts'
import AdminTickets from './tickets'
import AdminTransactions from './transactions'

let divHeight = 0
let pages = 1
let activePage = 1
let limit = 0

class ManagerPage extends React.Component {

  constructor(props) {
    super(props)
    this.wc = new WebClient();
    this.state = {
      activeTab: 0
    }
  } 

  componentDidMount() {
    
  }

  render() {
    const { activeTab } = this.state
    return (
      <div className="w-full h-full">

            <div className="manager-tabs">
                
                <div onClick={() => { this.setState({activeTab: 0}) }} className={classNames({
                                    [`manager-tab-item`]: true,
                                    'active': Boolean(activeTab == 0)
                                })}>
                    <i className="fas fa-users" />
                    <span><FormattedMessage id="accounts" /></span>
                </div>

                <div onClick={() => { this.setState({activeTab: 1}) }} className={classNames({
                                    [`manager-tab-item`]: true,
                                    'active': Boolean(activeTab == 1)
                                })}>
                    <i className="fas fa-ticket-alt" />
                    <span><FormattedMessage id="tickets" /></span>
                </div>

                <div onClick={() => { this.setState({activeTab: 2}) }} className={classNames({
                                    [`manager-tab-item`]: true,
                                    'active': Boolean(activeTab == 2)
                                })}>
                    <i className="fas fa-list" />
                    <span><FormattedMessage id="transactions" /></span>
                </div>

                <div onClick={() => { this.props.onLogout() }} className={classNames({
                                    [`manager-tab-item`]: true,
                                    'active': Boolean(activeTab == 3)
                                })}>
                    <i className="fas fa-sign-out-alt" />
                    <span><FormattedMessage id="logout" /></span>
                </div>

            </div>

            
            {
            (activeTab == 0) && (
            <AdminAccounts />
            )
            }

            {
            (activeTab == 1) && (
            <AdminTickets />
            )
            }

            {
            (activeTab == 2) && (
            <AdminTransactions />
            )
            }

      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(injectIntl(ManagerPage))
