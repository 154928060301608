// const base_url = 'http://www.ekol24.com.tr/'
// http://www.ekol24.com.tr/ekol/user/getLiveCasinos?authToken=s3cr3tV4lu3
// const base_url = 'http://ekol.ekoapi.com/'
// const base_url = 'https://wingo24.com'
const base_url = 'https://api.ekol77.com'
const authToken = 's3cr3tV4lu3'
function template(strings, ...keys) {
  return (function (...values) {
    var dict = values[values.length - 1] || {};
    var result = [strings[0]];
    keys.forEach(function (key, i) {
      var value = Number.isInteger(key) ? values[key] : dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  });
}
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
export default Object.freeze({
  base_url,
  reqId: "1ab2cd3ef",
  urls : {
    // logn: `${base_url}ekol/user/userLogin?authToken=${authToken}`,
    // live: `${base_url}ekol/api/lives?authToken=${authToken}`,
    // lang: `${base_url}ekol/user/getLanguages?authToken=${authToken}`,
    // data: `${base_url}ekol/user/getUserData?authToken=${authToken}`,
    // csno: `${base_url}ekol/user/getLiveCasinos?authToken=${authToken}`,
    // bngo: `${base_url}ekol/casinos/get/tombalalive?authToken=${authToken}`,
    // opcs: `${base_url}ekol/casinos/open?authToken=${authToken}`,
    // sprt: `${base_url}ekol/api/sports?authToken=${authToken}`,
    // mtch: `${base_url}ekol/api/matches?authToken=${authToken}`,
    // ctgr: `${base_url}ekol/api/categories?authToken=${authToken}`,
    // tour: `${base_url}ekol/api/tournaments?authToken=${authToken}`,
    // ucps: `${base_url}ekol/user/getCoupons?authToken=${authToken}`,
    // ucpd: `${base_url}ekol/user/getCouponDetail?authToken=${authToken}`,
    // utrs: `${base_url}ekol/user/getTransactions?authToken=${authToken}`,
    // socket: 'http://filliale.ekoapi.com/'
    // socket: 'http://159.69.59.155:3003/',
    // socket: 'wss://data.apiekol.com/wsold',
    socket: 'wss://lv.apiekol.com/wsold',
    mdetail: 'https://href.li/?https://cs.betradar.com/ls/widgets/?/universalsoftwaresolutions/en/Europe:Berlin/page/lmts#matchId=',
    // sdetail: 'https://href.li/?http://stats.betradar.com/s4/?clientid=850&matchid=',
    sdetail: 'http://www.ekol24.com/ekol/welcome/getLeagueFixture/',
    bingo: 'http://embed.tombalaklas.com?token=8873c865797d618a4bb6e750a75e3ee3&amp;sendData=1&amp;origin=http://localhost:3001'
  },
  uris : {
    logn: `ekol/user/userLogin?authToken=${authToken}`,
    // live: `ekol/api/lives3?authToken=${authToken}`,
    live: `sportbooks/v1/lives3`,
    odds: `ekol/api/odds?authToken=${authToken}`,
    livo: `sportbooks/v1/liveodds?authToken=${authToken}`,
    lang: `ekol/user/getLanguages?authToken=${authToken}`,
    cntr: `ekol/user/getCountries?authToken=${authToken}`,
    curr: `ekol/user/getCurrencies?authToken=${authToken}`,
    ttic: `ekol/user/ticketCheckTerminal?authToken=${authToken}`,
    ttbc: `ekol/user/bingoCheckTerminal?authToken=${authToken}`,
    copc: `ekol/user/couponCanceled?authToken=${authToken}`,
    usru: `ekol/user/updateProfile?authToken=${authToken}`,
    usrd: `ekol/user/getUserDetail?authToken=${authToken}`,
    usrb: `ekol/user/getUserBills?authToken=${authToken}`,
    rsta: `ekol/user/cutOffUserBill?authToken=${authToken}`,
    usrp: `ekol/user/changePassword?authToken=${authToken}`,
    data: `ekol/user/getUserDataTerminal?authToken=${authToken}`,
    csno: `ekol/user/getLiveCasinos?authToken=${authToken}`,
    csnw: `ekol/user/getAllCasinosNew?authToken=${authToken}`,
    tmvd: `ekol/user/getBingoVideo?authToken=${authToken}`,
    bngo: `ekol/casinos/get/tombalalive?authToken=${authToken}`,
    opcs: `ekol/casinos/open?authToken=${authToken}`,
    sprt: `ekol/api/sports?authToken=${authToken}`,
    mtch: `ekol/api/matches?authToken=${authToken}`,
    mtcr: `ekol/api/results?authToken=${authToken}`,
    ctgr: `ekol/api/categories?authToken=${authToken}`,
    tour: `ekol/api/tournaments?authToken=${authToken}`,
    ucps: `ekol/user/getCoupons?authToken=${authToken}`,
    ucpd: `ekol/user/getCouponDetail?authToken=${authToken}`,
    seco: `ekol/user/sellCoupon?authToken=${authToken}`,
    prsc: `ekol/user/printerstatus?authToken=${authToken}`,
    prst: `ekol/user/printerstatuswithdraw?authToken=${authToken}`,
    prcn: `ekol/user/canceltransaction?authToken=${authToken}`,
    prcc: `ekol/user/printingCouponCancel?authToken=${authToken}`,
    addm: `ekol/terminal/deposit?authToken=${authToken}`,
    wtpr: `ekol/user/withdrawPrinter?authToken=${authToken}`,
    utrs: `ekol/user/getTransactions?authToken=${authToken}`,
    crcp: `ekol/couponapi/createcoupon?authToken=${authToken}`,
    wtcd: `ekol/user/winprint?tz=${tz}&type=2&authToken=${authToken}`,
  },
  tprt: {
    bingoprint: template`${base_url}/ekol/user/bingoprinter/${0}?userId=${1}&timezone=${tz}&${2}`,
  },
  prints : {
    winprint: base_url + '/ekol/user/winprint/{{lang}}/{{couponId}}?siteType={{siteType}}&timezone=' + tz,
    winprintbingo: base_url + '/ekol/user/winprintbingo/{{lang}}/{{couponId}}?siteType={{siteType}}&userId={{userId}}&timezone=' + tz,
    cancelprint: base_url + '/ekol/user/cancelprint/{{lang}}/{{couponId}}?timezone=' + tz,
    couponprint: base_url + '/ekol/user/printer/{{lang}}/{{couponId}}?timezone=' + tz,
    duplicateprint: base_url + '/ekol/user/printer/{{lang}}/{{couponId}}/duplicate?timezone=' + tz,
    withdrawprint: base_url + '/ekol/user/withdrawPrinter/{{lang}}/{{transactionId}}?userId={{userId}}&timezone=' + tz,
    resetprint: base_url + '/ekol/user/resetprint/{{lang}}/{{billId}}?timezone=' + tz,
    bingoprint: base_url + '/ekol/user/bingoprinter/{{lang}}?userId={{userId}}&timezone=' + tz + '&{{paramaters}}',
    directory: 'C:/tickets/',
    tmpDirectory: 'C:/ticketstmp/',
    filePathCashDevice: 'C:/cashdevice/Executer.exe',
    filePathUsername: 'C:/cashdevice/usrx.txt',
    filePathMoney: 'C:/cashdevice/cashdevice.json',
    filePathTicketControl: 'C:/cashdevice/ticketcontrols.json',
    // filePathDb: 'C:/cashdevice/'
  },
  prgPath: 'C:/terminal',
  files : {
    firstRun: 'firstrun.cfg',
    logs: 'terminal.log',
    settings: 'settings.json'
  },
  compare_field: 'oddstype',
  maxMatchItem : 9,
  couponLimits : {
    maxMultiOdds: 27,
    maxMultiCps: 10,
    maxOddsCoupon: 20,
    maxSystemCps: 10
  },

  defaultPrefs : {
    activeWindows : ['main'],
    windows : ['---','main','LiveMatches','TombalaVideo'],
    disabledWindows: [''],
    hasExternalDisplay : false,
    printer : 1,
    onlineCheck : 1,
    userType : "T"
  },
  languages: [
    {
      lang: "tr",
      label: "Türkçe"
    },
    {
      lang: "en",
      label: "English"
    },
    {
      lang: "de",
      label: "Deutsch"
    },
    {
      lang: "fr",
      label: "Français"
    },
    {
      lang: "aa",
      label: "عربى"
    },
    {
      lang: "az",
      label: "Azərbaycan"
    },
    {
      lang: "cw",
      label: "Curaçao"
    }    
  ],
  defaultColumns: [
    {
      sid: "3",
      markets: [4475, 4477, 4478]
    },
    {
      sid: "1",
      markets: [5498, 5506, 5499, 5500]
    },    
  ]
})