function nameReplacer(name, match, odd = "") {
    var replaceData = [
      {
        string: "Beraberlik",
        replace: "X"
      },
      {
        string: "W2",
        replace: "2"
      },
      {
        string: "W1",
        replace: "1"
      },
      {
        string: "Player 2",
        replace: "2"
      },
      {
        string: "Player 1",
        replace: "1"
      },
      {
        string: "Team 2",
        replace: "2"
      },
      {
        string: "Deplasman",
        replace: "2"
      },
      {
        string: "Dep.",
        replace: "2"
      },
      {
        string: "Team 1",
        replace: "1"
      },
      {
        string: "Ev.",
        replace: "1"
      },
      {
        string: "Ev Sahibi",
        replace: "1"
      },
      {
        string: "{hv}",
        replace:
          match && match.score
            ? match.score.split(":")[0]
            : match.oddsInfo
            ? match.oddsInfo.split("-")[0].split(":")[0]
            : "XXX"
      },
      {
        string: "{av}",
        replace:
          match && match.score
            ? match.score.split(":")[1]
            : match.oddsInfo
            ? match.oddsInfo.split("-")[0].split(":")[1]
            : "XXX"
      },
      {
        string: "{t1}",
        replace: "1"
      },
      {
        string: ":-)",
        replace: ""
      },
      {
        string: "(-)",
        replace: match && match.score ? "(" + match.score + ")" : ""
      },
      {
        string: "{t2}",
        replace: "2"
      },
      {
        string: "{sw}",
        replace: odd && odd.sq ? odd.sq + "." : 0
      },
      {
        string: "{pw}",
        replace: odd && odd.sq ? odd.sq + "." : 0
      },
      {
        string: "{h}",
        replace: odd && odd.specialvalue ? odd.specialvalue : 0
      },
      {
        string: "{-h}",
        replace: odd && odd.specialvalue ? "-" + odd.specialvalue : 0
      }
    ];
    replaceData.forEach(r => {
      if (typeof name == "string" && name.includes(r.string))
        name = name.replace(r.string, r.replace);
    });
    return name;
  }
  
  export default nameReplacer;
  