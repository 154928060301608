import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import classNames from 'classnames'
import CButton from './button';
import { Redux, WebClient, constants, UpDown } from '../../../../components'
import moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js'

let layout = {
      default: [
          '1 2 3',
          "4 5 6",
          "7 8 9",
          ". 0 {bksp}"
      ]
}

let buttonTheme = [
    {
      class: "keyboard-close",
      buttons: "{close}"
    },
    {
      class: "keyboard-clear",
      buttons: "{clear}"
    },  
    {
        class: "keyboard-clear",
        buttons: "{del}"
      },        
    {
      class: "keyboard-search",
      buttons: "{search}"
    },
    {
      class: "keyboard-caps",
      buttons: "{space} {lock} {shift} {bksp}"
    },    
]

class CouponNumpad extends React.Component {

    state = {
        input: "",
        tab: 0,
        checkedTab: 0,
        checked: false,
        ticket: null,
        ticketContent: [],
        sellType: null,
        bingoData: null,
        cardInfo: null
    }

    componentDidMount() {
        this.setState({input: this.props.keyword})
        setTimeout(() => {
            this.keyboard.setInput(this.props.keyword)
        }, 500)
    }

    onChange = (input) => {
        this.setState({input})
    }

    onKeyPress = (button) => {
        const { input } = this.state

        if (button == "{bksp}") {

            let a = input.substring(0, input.length - 1)
            if (a.endsWith(".")) a = a.substring(0, a.length - 1)
            this.setState({input: a})

        } else {
            if (button == "." && input.includes(".")) return false
    
            let c = input.split(".")[1]
    
            if (c?.length == 2) return false
    
            let a = input + button

            let b = parseFloat(a)

            if (b > this.props.member.userCredit) a = this.props.member.userCredit

            this.setState({input: a})
        }
    }

    _approve() {
        Redux.updateCouponBottom({bet_amount: parseFloat(this.state.input)})
        this.props.onClose()        
    }

    render() {
        const { input, checked  } = this.state
        const { intl, member } = this.props
        return (
        <div className="w-full">

            <div className="w-full">
                <div className="coupon-numpad-input uppercase">
                    <div className="admin-password-input">
                        <span><FormattedMessage id="bet_amount" /></span>
                        <span>{input}</span>
                    </div>
                </div>
            </div>

            <div className="w-full">
                <Keyboard
                    keyboardRef={(ref) => this.keyboard = ref}
                    layout={layout}
                    buttonTheme={buttonTheme}
                    maxLength={10}
                    display={{
                        "{search}": intl.formatMessage({id: "search"}),
                        "{space}": intl.formatMessage({id: "Space"}),
                        "{close}": intl.formatMessage({id: "close"}),
                        "{bksp}": intl.formatMessage({id: "<"}),
                        "{del}": intl.formatMessage({id: "<"}),
                        "{lock}": intl.formatMessage({id: "CAPS"}),
                        "{shift}": intl.formatMessage({id: "SHIFT"}),
                        "{clear}": intl.formatMessage({id: "Clear"})
                    }}
                    // onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                />
            </div>

            <div className="w-full h-common mt-m">
                <CButton uppercase="uppercase" translate={true} width="w-full" text="approve" active={false} onClick={() => { this._approve() }} />
            </div> 

            <div className="w-full h-common mt-m">
                <CButton uppercase="uppercase" translate={true} width="w-full" text="set_all_credit" active={false} onClick={() => { this.setState({input: member.userCredit}) }} />
            </div>             

            <div className="w-full h-common mt-m">
                <CButton uppercase="uppercase" translate={true} width="w-full" text="cancel" active={false} onClick={() => { this.props.onClose() }} />
            </div>             

        </div>
        );
    }
}

const msp = (state) => {
    return {
        config: state.config,
        member: state.member,
        settings: state.settings,
        coupon_bottom: state.coupon_bottom
    }
}

export default connect(msp)(injectIntl(CouponNumpad))