var data = {
    "1": [
      {
        id: 0,
        title: "all"
      },
      {
        id: 1,
        title: "OT_102_20",
        ids: [
          5498,
          8988,
          11915,
          9014,
          12553,
          11381,
          6593,
          6594,
          8986,
          8987,
          8983,
          11962,
          10328,
          11097,
          11205,
          11936
        ]
      },
      {
        id: 2,
        title: "highlights",
        ids: [
          8702,
          11896,
          8958,
          13568,
          8959,
          8960,
          10334,
          10336,
          9065,
          9066,
          9067,
          10346,
          10174,
          10175,
          10176,
          11877,
          10991,
          10994,
          10995,
          10996,
          11886,
          11887,
          11888,
          11889,
          11890,
          11891,
          11892,
          11893,
          11894
        ]
      },
      {
        id: 3,
        title: "minute",
        ids: [
          11870,
          10400,
          10412,
          10413,
          10414,
          10415,
          10416,
          8929,
          8930,
          8932,
          8933,
          11788,
          11789,
          11790,
          11791,
          11792,
          11793,
          11794,
          11782,
          11783,
          11784,
          11785,
          11786,
          11787,
          10147,
          10971,
          10972,
          10973,
          10974,
          10975,
          10976,
          11914
        ]
      },
      {
        id: 4,
        title: "OTL_76",
        ids: [
          5500,
          5501,
          5502,
          10591,
          5516,
          5517,
          8966,
          5520,
          8725,
          11536,
          11537,
          9060,
          // IDS
          6498,
          6499,
          8725
        ]
      },
      {
        id: 5,
        title: "1st_half",
        ids: [
          6593,
          6594,
          6534,
          10399,
          6543,
          6539,
          8711,
          8713,
          8726,
          8986,
          8987,
          9027,
          9028,
          10428,
          11024,
          // IDS
          6502,
          6503,
          6504,
          11211,
          11986,
          6505,
          8718,
          10435
        ]
      },
      {
        id: 6,
        title: "2nd_half",
        ids: [
          8712,
          8728,
          8727,
          9027,
          9028,
          11025,
          // IDS
          10831,
          8725,
          11536,
          11537,
          11549,
          11550,
          11551,
          11546,
          11547,
          11548,
          11542,
          11543,
          11554,
          11555,
          8715
        ]
      },
      {
        id: 7,
        title: "OTL_38",
        ids: [10046, 6500, 11035, 8962, 8724, 8963, 5503, 5504]
      },
      {
        id: 8,
        title: "corners",
        ids: [
          11585,
          6510,
          10411,
          10966,
          10967,
          10968,
          10969,
          10970,
          10473,
          10472,
          10465,
          9136,
          9057,
          9137,
          9139,
          //IDS
          6506,
          8956,
          6507,
          6508,
          6509,
          11383,
          11578,
          11384,
          8957,
          6510,
          10371,
          10373,
          10257,
          9902,
          9903,
          9904,
          10824,
          11629,
          10825,
          10391,
          10401,
          10402,
          10403,
          10404,
          10405,
          10406,
          10407,
          10408,
          10467,
          11789,
          11783,
          8720,
          8721,
          11630,
          11631,
          10444,
          10446,
          10447,
          10448,
          10450,
          10451
        ]
      },
      {
        id: 9,
        title: "cards",
        ids: [
          13377,
          6516,
          9646,
          9649,
          6517,
          6518,
          6519,
          11385,
          9650,
          10409,
          10381,
          10383,
          10384,
          8722,
          10424,
          10425,
          10821,
          10822,
          8723,
          11871,
          11872,
          10961,
          10962,
          10963,
          10964,
          10400,
          10412,
          10413,
          10414,
          10415,
          10416,
          13193,
          10453,
          10965,
          11795,
          6512,
          8703,
          11897,
          11898,
          11916,
          8958,
          13568,
          11917,
          10474,
          10475,
          10466,
          10468,
          9136,
          9057,
          9138,
          9139,
          11790,
          11784,
          11926,
          11923,
          11921
        ]
      },
      {
        id: 10,
        title: "goals",
        ids: [
          8706,
          8708,
          11607,
          11608,
          13502,
          13503,
          13504,
          13505,
          8945,
          8944,
          11869,
          11870,
          11780,
          8705,
          8707,
          8711,
          8713,
          8712,
          8728,
          9027,
          9028,
          11885,
          11204,
          10841,
          11164,
          11165,
          11961,
          11907,
          11908,
          11909
        ]
      },
      {
        id: 11,
        title: "homeTeam",
        ids: [
          5501,
          8706,
          11607,
          10616,
          7642,
          6498,
          6503,
          11559,
          11544,
          11536,
          11550,
          11547,
          11554,
          8711,
          8712,
          11024
        ]
      },
      {
        id: 12,
        title: "awayTeam",
        ids: [
          5502,
          8708,
          11608,
          10617,
          7643,
          6499,
          6504,
          11560,
          11545,
          11553,
          11537,
          11551,
          11548,
          11555,
          8713,
          8728
        ]
      },
      {
        id: 13,
        title: "goalTime",
        ids: [5505, 11977, 11978, 11979, 11980, 10147]
      },
      {
        id: 14,
        title: "tackle",
        ids: [11779, 11588, 11589]
      },
      {
        id: 15,
        title: "shots",
        ids: [
          10346,
          10347,
          10350,
          10174,
          10175,
          10176,
          11633,
          10392,
          10393,
          10395,
          10396,
          10397,
          10398,
          11856,
          11857,
          11859,
          11858,
          11861,
          11860,
          11582,
          11873,
          11874,
          11876,
          11875,
          11877,
          11878,
          11884
        ]
      },
      {
        id: 16,
        title: "saves",
        ids: [10991, 10992, 10993, 10994, 10995, 10996, 11882]
      },
      {
        id: 17,
        title: "foul",
        ids: [
          10334,
          10335,
          10336,
          9065,
          9066,
          9067,
          10382,
          10385,
          10386,
          10340,
          10387,
          10388,
          11851,
          11852,
          11853,
          10341,
          11854,
          11855,
          10343,
          10342,
          11924
        ]
      },
      {
        id: 18,
        title: "penalty",
        ids: [8702, 11895, 11896, 11780, 11781, 8958, 13568]
      },
      {
        id: 19,
        title: "goalKick",
        ids: [
          11862,
          11863,
          11864,
          11865,
          11866,
          11867,
          11965,
          11966,
          11967,
          11968,
          11969,
          11970,
          11971,
          11972,
          11883,
          11792,
          11786
        ]
      },
      {
        id: 20,
        title: "throwIn",
        ids: [
          11505,
          11506,
          11508,
          11511,
          11512,
          11514,
          11798,
          11515,
          11516,
          11520,
          11517,
          11518,
          11519,
          11973,
          11974,
          11976,
          11975,
          11590,
          12879,
          12881,
          12880,
          12808,
          11793,
          11787,
          11924
        ]
      },
      {
        id: 21,
        title: "shotOnTarget",
        ids: [
          10346,
          10347,
          10350,
          10174,
          10175,
          10176,
          11633,
          10392,
          10393,
          10395,
          10396,
          10397,
          10398,
          11856,
          11857,
          11859,
          11858,
          11861,
          11860,
          11582
        ]
      },
      {
        id: 22,
        title: "offside",
        ids: [
          10330,
          10331,
          10333,
          9062,
          9063,
          9064,
          11632,
          10389,
          10390,
          11586,
          11587,
          11843,
          11844,
          11845,
          11846,
          11847,
          11849,
          11848,
          11850,
          10418,
          10419,
          10420,
          10421,
          10422,
          10423,
          10345,
          11581
        ]
      }
    ],
    "3": [
      {
        id: 0,
        title: "all"
      },
      {
        id: 1,
        title: "OT_2_381",
        ids: [4476, 4475, 11462, 10886, 10883, 12036]
      },
      {
        id: 2,
        title: "half",
        ids: [6631, 6630, 6632, 6633, 6634, 6635, 6636]
      },
      {
        id: 3,
        title: "quarter",
        ids: [
          4483,
          4482,
          4484,
          6626,
          10894,
          6627,
          6628,
          1895,
          12037,
          8650,
          8652,
          8655,
          8656,
          6629
        ]
      },
      {
        id: 4,
        title: "OTL_76",
        ids: [4478, 4479, 4480]
      },
      {
        id: 5,
        title: "OTL_26",
        ids: [4481, 11994, 11993]
      },
      {
        id: 6,
        title: "OTL_38",
        ids: [4477]
      }
    ],
    "4": [
      {
        id: 0,
        title: "all"
      },
      {
        id: 1,
        title: "OT_5_382",
        ids: [2434, 8690, 8940, 6580]
      },
      {
        id: 2,
        title: "set",
        ids: [
          2445,
          2449,
          2448,
          6581,
          6582,
          7644,
          7645,
          7646,
          7647,
          6605,
          10746,
          10747,
          2447,
          8937,
          8936,
          8938,
          8935
        ]
      },
      {
        id: 3,
        title: "m_500_1",
        ids: [
          6583,
          10667,
          10634,
          10637,
          10635,
          10638,
          10636,
          10639,
          10640,
          10641,
          10642,
          10644,
          10645
        ]
      },
      {
        id: 4,
        title: "point",
        ids: [6584, 10646, 10647, 10648, 10649, 10650, 10668, 10668, 10631, 11530]
      }
    ],
    "5": [
      { id: 0, title: "all" },
      {
        id: 1,
        title: "OT_5_382",
        ids: [
          399,
          8692,
          8694,
          8691,
          8688,
          5475,
          11123,
          409,
          5480,
          5485,
          5481,
          5486,
          12657,
          5478,
          5479,
          11507,
          9166,
          13588
        ]
      },
      {
        id: 2,
        title: "set",
        ids: [
          11484,
          11485,
          7632,
          11992,
          7633,
          7634,
          7635,
          7638,
          7639,
          8657,
          8658,
          8660,
          8661,
          10599,
          7627,
          7628,
          12611,
          12625,
          12624,
          12626,
          12640,
          12639,
          12641,
          12642,
          12643
        ]
      }
    ]
  };
  
  export default data;
  