import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import classNames from 'classnames'
import CButton from './button';
import { Redux, WebClient, constants, UpDown } from '../../../../components'
import moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js'

let layout = {
      default: [
          '1 2 3',
          "4 5 6",
          "7 8 9",
          "{clear} 0 {bksp}"
      ]
}

let buttonTheme = [
    {
      class: "keyboard-close",
      buttons: "{close}"
    },
    {
      class: "keyboard-clear",
      buttons: "{clear}"
    },    
    {
      class: "keyboard-search",
      buttons: "{search}"
    },
    {
      class: "keyboard-caps",
      buttons: "{space} {lock} {shift} {bksp}"
    },    
]

class AdminLogin extends React.Component {

    state = {
        input: "",
        tab: 0,
        checkedTab: 0,
        checked: false,
        ticket: null,
        ticketContent: [],
        sellType: null,
        bingoData: null,
        cardInfo: null
    }

    componentDidMount() {
        let currentLocalDate = new Date();
        console.log(currentLocalDate.getTimezoneOffset())
        this.setState({input: this.props.keyword})
        console.log(this.keyboard)
        // this.keyboard.setInput(this.props.keyword)
    }

    onChange = (input) => {
        this.setState({input})
        console.log(input)
        // this.props.onChange(input)
    }

    onKeyPress = (button) => {
        console.log("Button pressed", button);
        if (button === "{shift}" || button === "{lock}") this.handleShift();
        if (button === "{close}") {
            this.setState({input: ""}, () => {
                this.props.onClose()
            })
        }
        if (button === "{search}") {
            if (this.state.input?.length > 3) {
                this.props.onSearch()
            }
        }
        if (button === "{clear}") {
            this.keyboard.clearInput()
            this.setState({input: ""})
        }        
    }

    handleShift() {
        let currentLayout = this.keyboard.options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";

        this.keyboard.setOptions({
            layoutName: shiftToggle
        });
    }

    _check() {

        const { input } = this.state
        const { terminal_pass } = this.props

        if (input.length > 4) {
            console.log(this.props.terminal_pass)

            if (input == terminal_pass) {

                this.props.onLoginSuccess("Manager")

            } else {
                Redux.showToast("error", this.props.intl.formatMessage({id: "invalid_password"}))    
            }


        } else {
            Redux.showToast("warning", this.props.intl.formatMessage({id: "please_type_a_valid_password"}))
        }
    }  

    render() {
        const { input, checked  } = this.state
        const { intl } = this.props
        return (
        <div className="search-overlay">

            <div 
                className={classNames({
                    [`w-1/3`]: !checked
                })}>

            <div className="w-full">
                <div className="search-overlay-input uppercase">
                    <div className="admin-password-input">
                        <span><FormattedMessage id="your_password" /></span>
                        <span>{input}</span>
                    </div>
                </div>
            </div>

            <div className="w-full">
                <Keyboard
                    keyboardRef={(ref) => this.keyboard = ref}
                    layout={layout}
                    buttonTheme={buttonTheme}
                    maxLength={10}
                    display={{
                        "{search}": intl.formatMessage({id: "search"}),
                        "{space}": intl.formatMessage({id: "Space"}),
                        "{close}": intl.formatMessage({id: "close"}),
                        "{bksp}": intl.formatMessage({id: "<"}),
                        "{lock}": intl.formatMessage({id: "CAPS"}),
                        "{shift}": intl.formatMessage({id: "SHIFT"}),
                        "{clear}": intl.formatMessage({id: "Clear"})
                    }}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                />
            </div>

            <div className="w-full h-common mt-2">
                <CButton uppercase="uppercase" translate={true} width="w-full" text="login" active={false} onClick={() => { this._check() }} />
            </div> 

            <div className="w-full h-common mt-2">
                <CButton uppercase="uppercase" translate={true} width="w-full" text="close" active={false} onClick={() => { this.props.onClose() }} />
            </div>             

            </div>


           
        </div>
        );
    }
}

const msp = (state) => {
    return {
        config: state.config,
        member: state.member,
        settings: state.settings,
        terminal_pass: state.terminal_pass
    }
}

export default connect(msp)(injectIntl(AdminLogin))