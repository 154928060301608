import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { constants } from '..'
import classNames from 'classnames'

class UpDown extends React.Component {

    static defaultProps = {
        size: "sm",
        mb: "mb-2",
        px: "px-1",
        py: "py-1",
        disabled: false,
        upClick: () => {},
        downClick: () => {}
    }

    render() {
        const { upClick, downClick, size, mb, disabled, px, py } = this.props
            return (
                <div 
                className={classNames({
                    [`up-down-cont`]: true,
                    [`size-${size}`]: true,
                    [`${mb}`]: true,
                    [`${px}`]: true,
                    [`${py}`]: true
                })}>
                    <button disabled={disabled} onClick={upClick} className="up-down-button">
                        <i className="fas fa-chevron-up" />
                    </button>
                    <button disabled={disabled} onClick={downClick} className="up-down-button">
                        <i className="fas fa-chevron-down" />
                    </button>            
                </div>
            );
    }
}

export default UpDown;