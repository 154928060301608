import React from 'react'


import moment from 'moment'

import "moment/locale/tr"
import "moment/locale/en-gb"
import "moment/locale/de"
import "moment/locale/ar"
import "moment/locale/az"

// moment.locale("tr")

let interval = null

export default class Clock extends React.Component {

  state = {
    time: new Date()
  }

  componentDidMount() {
    interval = setInterval(() => {
      this.setState({time: new Date()})
    }, 1000)
  }

  componentWillUnmount() {
    if (interval != undefined || interval !== null) {
      clearInterval(interval)
    }
  }

  render() {

    const { time } = this.state

    return moment(time).format('DD.MM.YYYY HH:mm:ss')
  }
}
