import React from 'react'
import { TextInput } from 'react-native'
import { connect } from 'react-redux'
import { Redux, WebClient, constants, Overlay, getUserData } from '../../../components'
import { FormattedMessage, injectIntl } from 'react-intl'
import assets from '../../../components/utils/assets'
import { IntlContext } from '../../../components/intl-providers/main-intl-provider'
import Swal from 'sweetalert2/src/sweetalert2.js'

import MainHeader from './components/header'
import TabItem from './components/tab-item'
import Clock from './components/clock'


import Coupon from './coupon'
import TicketCheck from './components/ticket-check'
import AdminLogin from './components/admin-login'

import Matches from './matches'
import LiveMatches from './live-matches'
import LiveBingo from './live-bingo'
import LiveCasino from './live-casino'
import SlotGames from './slot-games'
import VirtualGames from './virtual-games'
import ManagerPage from './manager/index'

import TicketDetail from './manager/ticket-detail'

let listener_initialized = false
let barcode_value = [];

class Main extends React.Component {

  constructor(props) {
    super(props)
    this.wc = new WebClient()
    this.state = {
      activeScreen: "LiveMatches",
      isLanguageOverlayOpen: false,
      showCoupon: false,
      showTicketCheck: false,
      showPasswordPanel: false,
      barcodeValue: ""
    }
  }

  componentDidMount() {
    this._initBarcodeListener();
  }

  _initBarcodeListener() {
    let barcode = "";
    let interval;
    document.addEventListener('keydown', (event) => {
      console.log("keydown", event.code)

        if (interval) clearInterval(interval)
        if (event.code == "Enter") {
            
          if (barcode.length > 0) {
            this._handleBarcode(barcode)
            barcode = "";
            return;
          }
  
        }
  
        if (event.code != 'Shift' && event.code != 'ShiftLeft' && event.code != 'ShiftRight') {
          barcode += event.key
        }
        
        interval = setInterval(() => { barcode = "" }, 20)

    })

  }

  _handleBarcode(b) {
    this.setState({showTicketCheck: true, barcodeValue: b})
  }  

  openCoupon() {
    this.setState({showCoupon: true})
  }

  closeCoupon() {
    this.setState({showCoupon: false})
  }

  _checkPrinterStatus() {

    if (this.props.member.userCredit <= 0) {
      Redux.showToast("error", "insufficient_credit")
      return;
    }    

    if (window?.ipcRenderer === undefined) {
      Redux.showToast("error", "printer_was_not_ready")
      return;
    }

    if (window?.ipcRenderer && !listener_initialized) {
        window.ipcRenderer.on('printerStatusResponse', (event, args) => {
            if (args.status) {
              this._printBalance()
            } else {
              Redux.showToast("error", "printer_was_not_ready")
            }
        }); 
        listener_initialized = true
    }        

    if (window?.ipcRenderer) {
        window.ipcRenderer.send("printerStatusRequest", "_")
    }       
  }  

  _printBalance() {
    Swal.fire({
        title: this.props.intl.formatMessage({id: "withdraw_balance"}),
        text: this.props.intl.formatMessage({id: "are_you_sure_you_want_to_withdraw_your_balance"}),
        icon: 'warning',
        confirmButtonText: 'Approve',
        denyButtonText: 'Cancel',
        showDenyButton: true,
    }).then((response) => {
        // console.log(response)
        if (response.isConfirmed) {
          this._createWithdrawTransaction()
        }
    })
  }

  _createWithdrawTransaction() {
    this.wc.post(constants.uris.wtpr, {}, 
      {
        setLang: true,
        setUserId: true,
      }, false).then((response) => {
        if (response.status) {
          if (response.transactionId != 0) {
            this._sendBalanceToPrint(response.transactionId)
            getUserData()
          } else {
            Redux.showToast("error", this.props.intl.formatMessage({id: "a_technical_error_occured_please_try_again_later"}))
          }
          
        } else {
          Redux.showToast("error", response.msg)
        }

      })   
  }

  _sendBalanceToPrint(id) {

      let url = constants.prints.withdrawprint
                .replace("{{transactionId}}", id)
                .replace("{{lang}}", this.props.language)
                .replace("{{userId}}", this.props.member.userId)

      // console.log("url -->", url)

      if (window?.ipcRenderer) {
          /* PRINT COUPON */
          window.ipcRenderer.send("printUrl", {
              url,
              filename: `balance_withdraw_${id}`,
              type: 'ticket',
              couponId: id
          })
          /* PRINT COUPON */
      }

  }  

  render() {

    const { screens, activeScreen, isLanguageOverlayOpen, showCoupon, showTicketCheck, showPasswordPanel, barcodeValue } = this.state 
    const { intl, member, settings, show_ticket_detail } = this.props

    document.documentElement.lang = intl.locale;

    return (
      <div className="main-layout">

        <MainHeader 
          onLangClick={() => { this.setState({isLanguageOverlayOpen: true}) }}
          onChange={(e) => { this.setState({activeScreen: e}) }} 
          onTicketCheckClick={() => { this.setState({showTicketCheck: true}) }}
          onPrintBalanceClick={() => { this._checkPrinterStatus() }}
          onAdminLoginClick={() => { this.setState({showPasswordPanel: true}) }}
          activeScreen={activeScreen} 
        />

        <div className="main-layout-bottom">
          
          
          <div className="screen-layout">
          
          {
          activeScreen == "LiveMatches" && (
            <LiveMatches onCouponOpen={() => { this.openCoupon() }} />
          )
          }

          {
          activeScreen == "Matches" && (
            <Matches onCouponOpen={() => { this.openCoupon() }} />
          )
          } 

          {
          activeScreen == "LiveBingo" && (
            <LiveBingo />
          )
          }

          {
          activeScreen == "LiveCasino" && (
            <LiveCasino />
          )
          }
          
          {
          activeScreen == "SlotGames" && (
            <SlotGames />
          )
          }

          {
          activeScreen == "VirtualGames" && (
            <VirtualGames />
          )
          } 

          {
          activeScreen == "Manager" && (
            <ManagerPage onLogout={() => { this.setState({activeScreen: "LiveMatches"}) }} />
          )
          }                    

          </div>

        </div>

          {
          isLanguageOverlayOpen && (
            <Overlay onClose={() => { this.setState({isLanguageOverlayOpen: false}) }}>
            <IntlContext.Consumer>
              {context => {
                return (
                  <div className="choose-lang">
                    <h4><FormattedMessage id="choose_language" /></h4>
                    <div className="languages-cont">              
                      {
                      constants.languages.map((language, index) => {

                        if (language.lang == intl.locale) return null;

                        return (
                          <div onClick={() => { context.switchLanguage(language.lang) }} key={index} className="language-item">
                            <span className="icon" style={{ backgroundImage: `url('${assets[language.lang]}')` }}></span>
                          </div>
                        )
                      })
                      }
                    </div>                    
                  </div>
                )
              }}
            </IntlContext.Consumer>                
            </Overlay>
          )
          }

          {
          showCoupon && (
          <div className="coupon-overlay">

            <div className="coupon-overlay-left">

              <span><FormattedMessage id="credit" /></span>
              <span>{member?.userCredit}</span>

            </div>

            <div className="coupon-container">
              <Coupon onClose={() => { this.closeCoupon() }} />
            </div>            

          </div>
          )
          }

          {
          showTicketCheck && (
          <div className="coupon-check-overlay">

            <TicketCheck barcode={barcodeValue} onClose={() => { this.setState({showTicketCheck: false}) }} />

          </div>            
          )
          }

          {
          showPasswordPanel && (
          <div className="coupon-check-overlay">

            <AdminLogin onLoginSuccess={(e) => { this.setState({activeScreen: e, showPasswordPanel: false}) }} onClose={() => { this.setState({showPasswordPanel: false}) }} />

          </div>            
          )
          }     

          {
          show_ticket_detail?.show && (
          <div className="coupon-overlay">

            <div onClick={() => { Redux.hideTicketDetail() }} className="coupon-detail-overlay-left">
              
            </div>

            <div className="coupon-detail-container">
              
              <TicketDetail />

            </div>

          </div>
          )
          }

          {/* <TextInput value={emptyValue} onChangeText={(e) => { this.setState({emptyValue: e}) }} /> */}

      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config,
      member: state.member,
      settings: state.settings,
      show_ticket_detail: state.show_ticket_detail
  }
}

export default connect(msp)(injectIntl(Main))
