import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'
import classNames from 'classnames'

class ClassicStats extends React.Component {

  render() {
    const { match } = this.props

    return (
        <div className={classNames({"classic-stat-cont": true, [`type-${match.sportId}`]: true})}>

            {
            match.sportId == 1 && (
            <div className="classic-stats">

                <div className="classic-stats-title">
                    <div className="team">
                        {match.matchstatus == "paused" && ("HT")}
                        {match.matchstatus == "not_started" && (moment(match.matchDate).format("DD.MM - HH:mm"))}
                        {(match.matchstatus != "paused" && match.matchstatus != "ended" && match.matchstatus != "not_started") && (`${match.matchstatus} - ${match.matchtime}'`)}
                    </div>
                    <div className="title-col yellow-card"></div>
                    <div className="title-col red-card"></div>
                    <div className="title-col corner"></div>
                    <div className="title-col">1</div>
                    <div className="title-col">2</div>
                    <div className="title-col"><FormattedMessage id="match_score" /></div>
                </div>

                <div className="classic-stats-row">
                    <div className="team">{match.Home}</div>
                    <div className="row-col">{match?.stt?.c?.y?.h}</div>
                    <div className="row-col">{match?.stt?.c?.r?.h}</div>
                    <div className="row-col">{match?.stt?.cr?.h}</div>
                    <div className="row-col">0</div>
                    <div className="row-col">0</div>
                    <div className="row-col">{match?.scr?.h}</div>
                </div>
                <div className="classic-stats-separator"></div>
                <div className="classic-stats-row">
                    <div className="team">{match.Away}</div>
                    <div className="row-col">{match?.stt?.c?.y?.a}</div>
                    <div className="row-col">{match?.stt?.c?.r?.a}</div>
                    <div className="row-col">{match?.stt?.cr?.a}</div>
                    <div className="row-col">0</div>
                    <div className="row-col">0</div>
                    <div className="row-col">{match?.scr?.a}</div>
                </div>                        

            </div>
            )
            }

        </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config,
      coupon: state.coupon,
      member: state.member
  }
}

export default injectIntl(ClassicStats)
