import React from 'react'
import spinner from '../../../assets/common/ripple.svg'

class Spinner extends React.Component {

    render() {
        return (
        <img src={spinner} />
        );
    }
}

export default Spinner;
