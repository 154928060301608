import Axios from 'axios';
import constants from '../utils/constants'
import { store } from '../redux'
import { Redux } from '..';
import { injectIntl } from 'react-intl'
const io = require('socket.io-client')

export default class SocketUtil {
    
    constructor(props) {
        this.socket = null;
    }

    init() {
        this.connect();
    }

    connect() {
        this.socket = new WebSocket(constants.urls.socket);
        this.socket.onopen = (evt) => {this.onOpen(evt)}
        this.socket.onclose = (evt) => {this.onClose(evt)}
        this.socket.onmessage = (evt) => {this.onMessage(evt)}
        this.socket.onerror = (evt) => {this.onError(evt)}
    }

    onOpen(evt) {
        // console.log("on open")
        // console.log(evt)
    }

    onClose(evt) {
        // console.log("on close")
        // console.log(evt)
    }

    onMessage(evt) {
        try {
            this.processData(JSON.parse(evt.data))
        } catch { }
    }    

    onError(evt) {
        // console.log("on error")
        // console.log(evt)
    }    

    disconnect() {
        this.socket.close();
    }

    processData(f) {
        try {
            let d = store.getState().live_matches;
            let e = d?.filter(x => x.matchid == f?.matchid)
    
            if (e?.length > 0) {
                let changed = false
                e = e[0]

                // console.log(f)

                // console.log(e.Home + " " + e.Away)

                if (e?.redcardsaway != f?.cards?.redaway) {
                    e.redcardsaway = f.cards.redaway
                    changed = true
                }

                if (e?.redcardshome != f?.cards?.redhome) {
                    e.redcardshome = f.cards.redhome
                    changed = true
                }

                if (f?.cards?.redhome && f.cards.redhome != e?.stt?.c?.r?.h) {
                    e.stt.c.r.h = f.cards.redhome
                    changed = true
                }

                if (f?.cards?.redaway && f.cards.redaway != e?.stt?.c?.r?.a) {
                    e.stt.c.r.a = f.cards.redaway
                    changed = true
                }

                if (f?.cards?.yellowaway && f.cards.yellowaway != e?.stt?.c?.y?.a) {
                    e.stt.c.y.a = f.cards.yellowaway
                    changed = true
                }

                if (f?.cards?.yellowhome && f.cards.yellowhome != e?.stt?.c?.y?.h) {
                    e.stt.c.y.h = f.cards.yellowhome
                    changed = true
                }

                if (f?.corners?.home && f.corners.home != e?.stt?.cr?.h) {
                    e.stt.cr.h = f.corners.home
                    changed = true
                }

                if (f?.corners?.away && f.corners.away != e?.stt?.cr?.h) {
                    e.stt.cr.a = f.corners.away
                    changed = true
                }                

                if (e?.matchstatus != f?.matchstatus) {
                    e.matchstatus = f.matchstatus
                    changed = true
                }

                if (e?.score != f?.score?.matchscore) {
                    e.score = f.score.matchscore
                    changed = true
                }

                if (e?.matchtime != f?.time?.matchtime) {
                    e.matchtime = f.time.matchtime
                    changed = true
                }

                if (e?.iss != f?.iss) {
                    e.iss = f.iss
                    // console.log("iss changed", f.iss)
                    changed = true
                }

                if (e?.isv != f?.isv) {
                    e.isv = f.isv
                    // console.log("isv changed", f.isv)
                    changed = true
                }
                
                if (e?.extra_count != f?.oddsCount) {
                    e.extra_count = f.oddsCount
                    changed = true
                }

                if (e?.IsStatAvailable != f?.IsStatAvailable) {
                    e.IsStatAvailable = f.IsStatAvailable
                    changed = true
                }

                e.odds.map((o) => {

                    let z = f?.odds?.filter(x => x.oddstype == o.oddstype && x.special == (o.specialvalue + "****"))
                    if (z?.length > 0) {
                        z = z[0]
                        // console.log(z)
                        // console.log(o)

                        if (o?.active != z?.active) {
                            o.active = z.active
                            changed = true
                        }

                        if (o?.mostbalanced != z?.mostbalanced) {
                            o.mostbalanced = z.mostbalanced
                            changed = true
                        }      

                        if (o?.status != z?.status) {
                            o.status = z.status
                            changed = true
                        }
                        
                        if (o?.special != z?.special) {
                            o.special = z.special
                            changed = true
                        }

                        if (o?.iss != z?.iss) {
                            o.iss = z.iss
                            // console.log("market iss changed", z.iss)
                            changed = true
                        }
                        
                        if (o?.isv != z?.isv) {
                            o.isv = z.isv
                            // console.log("market isv changed", z.isv)
                            changed = true
                        } 
                        
                        o.odds.map((u) => {

                            let y = z?.odds?.filter(x => x.outcomeid == u.outComeId)
                            if (y.length > 0) {
                                y = y[0]

                                if (u?.active != y?.active) {
                                    u.active = y.active
                                    changed = true
                                }

                                if (u?.iss != y?.iss) {
                                    u.iss = y.iss
                                    // console.log("selection iss changed", y.iss)
                                    changed = true
                                }

                                if (u?.isv != y?.isv) {
                                    u.isv = y.isv
                                    // console.log("selection isv changed", y.isv)
                                    changed = true
                                }

                                // console.log(u)
                                // console.log(y)
                                
                                if (u?.odds != y?.odd) {
                                    let s = "inc"
                                    if (u.odds == y.odd) s = ""
                                    if (y.odd < u.odds) s = "dec"
                                    u.odds = y.odd
                                    u.animate = s
                                    changed = true
                                }               

                            }
                        })

                    }
                })


                if (changed) {
                    Redux.setMid(f.matchid)
                }

            }
        } catch {

        }        
    }

}