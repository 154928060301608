import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import classNames from 'classnames'
import CButton from './button';
import { Redux, WebClient, constants, UpDown } from '../../../../components'
import moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js'

let layout = {
      default: [
          '1 2 3',
          "4 5 6",
          "7 8 9",
          "{clear} 0 {bksp}"
      ]
}

let buttonTheme = [
    {
      class: "keyboard-close",
      buttons: "{close}"
    },
    {
      class: "keyboard-clear",
      buttons: "{clear}"
    },    
    {
      class: "keyboard-search",
      buttons: "{search}"
    },
    {
      class: "keyboard-caps",
      buttons: "{space} {lock} {shift} {bksp}"
    },    
]

class TicketCheck extends React.Component {

    static defaultProps = {
        barcode: ""
    }

    state = {
        input: "",
        tab: 0,
        checkedTab: 0,
        checked: false,
        ticket: null,
        ticketContent: [],
        sellType: null,
        bingoData: null,
        cardInfo: null
    }

    componentDidMount() {
        this._initBarcodeListener()
        // this.setState({input: this.props.keyword})
        if (this.props?.barcode?.length > 0) {
            this.setState({input: this.props.barcode}, () => {
                this._check()
            })
        }
    }

    _initBarcodeListener() {
        let barcode = "";
        let interval;
        document.addEventListener('keydown', (event) => {
          console.log("keydown", event.code)
    
            if (interval) clearInterval(interval)
            if (event.code == "Enter") {
                
              if (barcode.length > 0) {
                this._handleBarcode(barcode)
                barcode = "";
                return;
              }
      
            }
      
            if (event.code != 'Shift' && event.code != 'ShiftLeft' && event.code != 'ShiftRight') {
              barcode += event.key
            }
            
            interval = setInterval(() => { barcode = "" }, 20)
    
        })
    
      }
    
      _handleBarcode(b) {
        this.setState({input: b}, () => {
            this._check()
        })
      }      

    onChange = (input) => {
        this.setState({input})
        console.log(input)
        // this.props.onChange(input)
    }

    onKeyPress = (button) => {
        console.log("Button pressed", button);
        if (button === "{shift}" || button === "{lock}") this.handleShift();
        if (button === "{close}") {
            this.setState({input: ""}, () => {
                this.props.onClose()
            })
        }
        if (button === "{search}") {
            if (this.state.input?.length > 3) {
                this.props.onSearch()
            }
        }
        if (button === "{clear}") {
            this.keyboard.clearInput()
            this.setState({input: ""})
        }        
    }

    handleShift() {
        let currentLayout = this.keyboard.options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";

        this.keyboard.setOptions({
            layoutName: shiftToggle
        });
    }

    _check() {
        if (this.state.input.length > 4) {

            const wc = new WebClient();
            if (this.state.tab == 0) {
    
                wc.post(constants.uris.ttic, 
                {
                    code: this.state.input
                }, 
                {
                    setLang: true,
                    setUserId: true
                }).then((response) => {
                    
                    console.log(response)
                    if (response.status) {
                        this.setState({checked: true, ticket: response.ticket, ticketContent: response.ticketContent, sellType: response.sellType, checkedTab: 0})
                    } else {
                        Redux.showToast("error", response.msg)
                    }
            
                })

            } else if (this.state.tab == 1) {

    
                wc.post(constants.uris.ttbc, 
                {
                    code: this.state.input
                }, 
                {
                    setLang: true,
                    setUserId: true
                }).then((response) => {
                    
                    console.log(response)
                    if (response.status) {
                        this.setState({checked: true, cardInfo: response.cardInfo, bingoData: response.bingoData, checkedTab: 1})
                    } else {
                        Redux.showToast("error", response.msg)
                    }
            
                })
            }

        }
    }

    _cancelTicket() {
        Swal.fire({
            title: this.props.intl.formatMessage({id: "cancel_ticket"}),
            text: this.props.intl.formatMessage({id: "are_you_sure"}),
            icon: 'warning',
            confirmButtonText: this.props.intl.formatMessage({id: "approve"}),
            denyButtonText: this.props.intl.formatMessage({id: "cancel"}),
            showDenyButton: true,
        }).then((response) => {
            console.log(response)
            if (response.isConfirmed) {
                this._approveCancel()
            }
        })
    }

    _approveCancel() {
        const wc = new WebClient();
        wc.post(constants.uris.copc, 
            {
                ticketId: this.state.ticket.id
            }, 
            {
                setLang: true,
                setUserId: true
            }).then((response) => {
                console.log(response)
                if (response.status) {
                    Redux.showToast("success", response.msg)
                    this._check()
                } else {
                    Redux.showToast("error", response.msg)
                }
            })
    }

    _sellTicket() {
        Swal.fire({
            title: this.props.intl.formatMessage({id: "sell_ticket"}),
            text: this.props.intl.formatMessage({id: "are_you_sure"}),
            icon: 'warning',
            confirmButtonText: this.props.intl.formatMessage({id: "approve"}),
            denyButtonText: this.props.intl.formatMessage({id: "cancel"}),
            showDenyButton: true,
        }).then((response) => {
            console.log(response)
            if (response.isConfirmed) {
                this._approveSell()
            }
        })
    }

    _approveSell() {
        const wc = new WebClient();
        wc.post(constants.uris.seco, 
            {
                ticketId: this.state.ticket.id,
                sellok: "1",
                siteType: "2"
            }, 
            {
                setLang: true,
                setUserId: true
            }).then((response) => {
                console.log(response)
                if (response.status) {
                    Redux.showToast("success", response.msg)
                    this._check()
                } else {
                    Redux.showToast("error", response.msg)
                }
            })
    }    

    _getStatusClass(c) {
        switch (c) {
            case "0":
                return "opened"
            case "1":
                return "win"
            case "-2":
                return "canceled"
            case "2":
                return "win"
            default:
                return "opened"
        }
    }

    _getStatusText(c) {
        switch (c) {
            case "0":
                return "open"
            case "1":
                return "win"
            case "-2":
                return "canceled"
            case "2":
                return "selled"
            default:
                return "opened"
        }
    }    

    render() {
        const { input, tab, checked, sellType, ticketContent, ticket, bingoData, cardInfo, checkedTab } = this.state
        const { intl, barcode } = this.props
        return (
        <div className="search-overlay">

            <div 
                className={classNames({
                    [`w-1/3`]: !checked,
                    'w-1/4': checked,
                    'absolute': checked,
                    'left-m': checked,
                    'top-m': checked
                })}>
            <div className="w-full ticket-check-tab relative">
                <div 
                    onClick={() => { this.setState({tab: 0}) }}
                    className={classNames({
                        [`ticket-check-tab-item`]: true,
                        'selected': Boolean(tab == 0)
                    })}>
                        <FormattedMessage id="sport_bets" />
                    </div>

                <div 
                    onClick={() => { this.setState({tab: 1}) }}
                    className={classNames({
                        [`ticket-check-tab-item`]: true,
                        'selected': Boolean(tab == 1)
                    })}>
                        <FormattedMessage id="live_bingo" />
                    </div>
            </div>

            <div className="w-full">
                <div className="search-overlay-input uppercase">
                    <FormattedMessage id="pin" /> : {input}
                </div>
            </div>

            <div className="w-full">
                <Keyboard
                    keyboardRef={(ref) => this.keyboard = ref}
                    layout={layout}
                    buttonTheme={buttonTheme}
                    display={{
                        "{search}": intl.formatMessage({id: "search"}),
                        "{space}": intl.formatMessage({id: "Space"}),
                        "{close}": intl.formatMessage({id: "close"}),
                        "{bksp}": intl.formatMessage({id: "<"}),
                        "{lock}": intl.formatMessage({id: "CAPS"}),
                        "{shift}": intl.formatMessage({id: "SHIFT"}),
                        "{clear}": intl.formatMessage({id: "Clear"})
                    }}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                />
            </div>

            <div className="w-full h-common mt-2">
                <CButton uppercase="uppercase" translate={true} width="w-full" text="approve" active={false} onClick={() => { this._check() }} />
            </div> 

            <div className="w-full h-common mt-2">
                <CButton uppercase="uppercase" translate={true} width="w-full" text="close" active={false} onClick={() => { this.props.onClose() }} />
            </div>             

            </div>

            {
            (checked) && (
                <div className="ticket-result-cont">
                    <div className="ticket-result-title">
                        <FormattedMessage id={checkedTab == 0 ? "sport_bets" : "live_bingo"} />
                    </div>
                    <div className="ticket-results">

                    {
                    (checkedTab == 0) && (
                    <>

                    {
                    sellType?.status && (
                    <div className="sell-coupon-cont">
                        <div onClick={() => { this._sellTicket() }}>
                            <FormattedMessage id="sell_coupon" />
                        </div>
                        <span>{sellType?.msg}</span>
                    </div>
                    )
                    }

                    {
                    (ticket?.status == "-2") && (
                    <div className="sell-coupon-cont">
                        <div>
                            <FormattedMessage id="this_coupon_was_canceled_on" values={{date: moment(ticket.payDate).format("DD.MM.YYYY HH:mm:ss")}} />
                        </div>
                    </div>
                    )
                    } 

                    {
                    (ticket?.status == "1") && (
                    <div className="sell-coupon-cont">
                        <div>
                            <FormattedMessage id="this_coupon_was_paid_on" values={{date: moment(ticket.payDate).format("DD.MM.YYYY HH:mm:ss")}} />
                        </div>
                    </div>
                    )
                    }  

                    {
                    (ticket?.status == "2") && (
                    <div className="sell-coupon-cont">
                        <div>
                            <FormattedMessage id="this_coupon_was_selled_on" values={{date: moment(ticket.payDate).format("DD.MM.YYYY HH:mm:ss")}} />
                        </div>
                    </div>
                    )
                    }                                                            

                    <table className="ticket-result-table">
                        <thead>
                            <tr>
                                <th><FormattedMessage id="coupon_no" /></th>
                                <th><FormattedMessage id="total_stake" /></th>
                                <th><FormattedMessage id="coupon_date" /></th>
                                <th><FormattedMessage id="type" /></th>
                                <th><FormattedMessage id="possible_gain" /></th>
                                <th><FormattedMessage id="bonus" /></th>
                                <th><FormattedMessage id="total" /></th>
                                <th><FormattedMessage id="coupon_number" /></th>
                                <th><FormattedMessage id="payout" /></th>
                                <th><FormattedMessage id="odds" /></th>
                                <th><FormattedMessage id="status" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{ticket?.ticketId}</td>
                                <td>{ticket?.totalStake}</td>
                                <td>{moment(ticket?.ticketDate).format("DD.MM.YYYY HH:mm")}</td>
                                <td>{ticket?.ticketType} ({ticket?.totalTickets})</td>
                                <td>{ticket?.maxPay}</td>
                                <td>{ticket?.bonusPay}</td>
                                <td>{ticket?.maxPay}</td>
                                <td>{ticket?.totalTickets}</td>
                                <td>{ticket?.paid == 0 ? "-" : ticket?.paid}</td>
                                <td>{ticket?.totalTipps}</td>
                                <td className={this._getStatusClass(ticket?.status)}><FormattedMessage id={this._getStatusText(ticket?.status)} /></td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="ticket-result-table">
                        <thead>
                            <tr>
                                <th><FormattedMessage id="type" /></th>
                                <th><FormattedMessage id="banko" /></th>
                                <th><FormattedMessage id="date" /></th>
                                <th><FormattedMessage id="teams" /></th>
                                <th><FormattedMessage id="bet" /></th>
                                <th><FormattedMessage id="prediction" /></th>
                                <th><FormattedMessage id="rate" /></th>
                                <th><FormattedMessage id="info" /></th>
                                <th><FormattedMessage id="status" /></th>
                                <th><FormattedMessage id="result" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            ticketContent?.map((item, index) => {
                                return (
                                <tr key={index}>
                                    <td>{item.isLive == 0 ? "S" : "L"}</td>
                                    <td>{item.isBank == 0 ? "B" : ""}</td>
                                    <td>{moment(item.matchDate).format("DD.MM.YYYY HH:mm")}</td>
                                    <td>{item.home} : {item.away}</td>
                                    <td>{item.typeName}</td>
                                    <td>{item.outComeName} {item.special == "****" ? "" : `(${item.special})`}</td>
                                    <td>{item.odds}</td>
                                    <td>{item.oddsInfo}</td>
                                    <td className={this._getStatusClass(item.status)}><FormattedMessage id={this._getStatusText(item.status)} /></td>
                                    <td>{item.Results}</td>
                                </tr>
                                )
                            })
                            }
                        </tbody>                        
                    </table>  

                    {
                    (sellType.status == false && ticket?.canceled == 1 && ticket?.paid == 0) && (
                    <div className="cancel-coupon-cont">
                        <div onClick={() => { this._cancelTicket() }}>
                            <FormattedMessage id="cancel_ticket" />
                        </div>
                    </div>
                    )
                    }

                    </>
                    )
                    }

                    {
                    (checkedTab == 1) && (
                    <>

                    <div className="bingo-ticket" style={{backgroundColor: cardInfo?.color ?? '#ccc'}}>
                        {
                        cardInfo?.numbers?.split(",")?.map((item, index) => {
                            return (
                                <div key={index} className="bingo-ticket-number">
                                    <div 
                                    className={classNames({
                                        'bingo-ticket-number-in': Boolean(item != 0),
                                        'bingo-ticket-number-in-empty': Boolean(item == 0),
                                    })}>
                                        {item == 0 ? "" : item}
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>

                    <div className="w-full flex flex-wrap flex-row items-start justify-start mt-2">

                        <div className="bingo-table-cont">
                        <div className="bingo-table-col">
                            <div className="bingo-col-1"><FormattedMessage id="pin" /></div>
                            <div className="bingo-col-2">{bingoData?.id}</div>
                        </div>
                        </div>

                        <div className="bingo-table-cont">
                        <div className="bingo-table-col">
                            <div className="bingo-col-1"><FormattedMessage id="deposited_amount" /></div>
                            <div className="bingo-col-2">{bingoData?.amountE}</div>
                        </div>
                        </div>

                        <div className="bingo-table-cont">
                        <div className="bingo-table-col">
                            <div className="bingo-col-1"><FormattedMessage id="card_number" /></div>
                            <div className="bingo-col-2">{cardInfo?.cardId}</div>
                        </div>
                        </div>

                        <div className="bingo-table-cont">
                        <div className="bingo-table-col">
                            <div className="bingo-col-1"><FormattedMessage id="game_id" /></div>
                            <div className="bingo-col-2">{JSON.parse(bingoData?.description)?.game_id}</div>
                        </div>
                        </div>

                        <div className="bingo-table-cont">
                        <div className="bingo-table-col">
                            <div className="bingo-col-1"><FormattedMessage id="create_time" /></div>
                            <div className="bingo-col-2">{bingoData?.processDate}</div>
                        </div>
                        </div>

                        <div className="bingo-table-cont">
                        <div className="bingo-table-col">
                            <div className="bingo-col-1"><FormattedMessage id="transaction_id" /></div>
                            <div className="bingo-col-2">{bingoData?.transactionId}</div>
                        </div>
                        </div>

                        <div className="bingo-table-cont">
                        <div className="bingo-table-col">
                            <div className="bingo-col-1"><FormattedMessage id="table_id" /></div>
                            <div className="bingo-col-2">{JSON.parse(bingoData?.description)?.table_id}</div>
                        </div>
                        </div>

                        <div className="bingo-table-cont">
                        <div className="bingo-table-col">
                            <div className="bingo-col-1"><FormattedMessage id="status" /></div>
                            <div className="bingo-col-2">
                                <FormattedMessage id={bingoData?.status == 1 ? "open" : "closed"} />
                            </div>
                        </div>                                                                                                                                                
                        </div>

                    </div>

                    </>
                    )
                    }

                    </div>
                    <UpDown 
                        size="md" 
                        upClick={() => {  }} 
                        downClick={() => {  }} 
                    />
                </div>
            ) 
            }

           
        </div>
        );
    }
}

export default injectIntl(TicketCheck);