import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import * as Redux from '../../../../components/redux'
import UpDown from '../../../../components/layout/up-down'
import moment from 'moment'
import classNames from 'classnames'

let scrollM = 0
let h = 300

class CStatisticIframe extends React.Component {

  constructor(props) {
    super(props)
    this.oddListDiv = React.createRef()
  } 

  render() {

    const { data, match, coupon, intl } = this.props
    console.log(data)

    let url = ""

    if (match?.IsStatAvailable) url = Redux.getStatisticsUrl(match.id)

    return (
        <div className="iframe-cont">
            <button onClick={() => { this.props.onClose() }} className="iframe-close"><i className="fas fa-times" /></button>

            {
            match?.IsStatAvailable && (
            <div className="detail-left">
                
                <div className="statistic-cont">
                    <iframe style={{ width: "100%", height: "100%" }} src={url}></iframe>
                </div>

            </div>
            )
            }

            <div className={classNames({"detail-right": true, "w-full-force": !Boolean(match?.IsStatAvailable)})}>
            
                <div className="match-detail-title">
                    <p>{match.tournamentName}</p>
                    <p>{match.homeTeam} -:- {match.awayTeam}</p>
                    <p>{moment(match.matchDate).format("DD.MM.YYYY HH:mm")}</p>
                </div>

                <div className="match-detail-cont" ref={this.oddListDiv}>
                    {
                    data.map((item, index) => {
                        return (
                            <div key={index} className="detail-odds-cont">
                                <p className="detail-odd-title">{item.typeName} {item.specialvalue?.length > 0 ? `(${item.specialvalue})` : ""}</p>
                                <div className="detail-odds">
                                    {
                                    item.odds.map((odd, yindex) => {

                                        let unique = `${match.id}-${odd?.Id}-${odd?.oddsType}-${odd?.outComeId}-${odd?.special}`
                                        let cp = coupon.filter(x => x.matchid == match.id)
                                        let is_added = false
                                        
                                        if (cp.length > 0) {
                                            cp = cp[0]
                                            is_added = Boolean(cp.odd.filter(x => x.unique == unique).length > 0)
                                        }

                                        return (
                                            <div key={yindex} className="detail-odd-item">

                                                {
                                                (odd?.isv && odd?.odds > 1 && item?.isv) ?
                                                <div onClick={() => { Redux.addOrRemoveOdd(match, odd, intl) }} className={classNames({
                                                    [`detail-odd-item-button`]: !is_added,
                                                    'detail-odd-item-button-in-coupon': is_added
                                                })}>
                                                    <span>
                                                        {
                                                        odd.outCome.trim() == "{t1}" 
                                                        ? 
                                                        match.homeTeam 
                                                        : 
                                                        (
                                                        odd.outCome.trim() == "{t2}" ?
                                                        match.awayTeam 
                                                        :
                                                        odd.outCome
                                                        )
                                                        }
                                                    </span>
                                                    <span>{odd.odds.toFixed(2)}</span>
                                                </div>                                                
                                                :
                                                (
                                                (odd?.iss && item?.iss) ?
                                                <div className="detail-odd-item-button opacity-30">
                                                    <span>
                                                        {
                                                        odd.outCome.trim() == "{t1}" 
                                                        ? 
                                                        match.homeTeam 
                                                        : 
                                                        (
                                                        odd.outCome.trim() == "{t2}" ?
                                                        match.awayTeam 
                                                        :
                                                        odd.outCome
                                                        )
                                                        }
                                                    </span>
                                                    <span>-</span>
                                                </div>
                                                :
                                                <div className="detail-odd-item-button">
                                                    <span>
                                                        {
                                                        odd.outCome.trim() == "{t1}" 
                                                        ? 
                                                        match.homeTeam 
                                                        : 
                                                        (
                                                        odd.outCome.trim() == "{t2}" ?
                                                        match.awayTeam 
                                                        :
                                                        odd.outCome
                                                        )
                                                        }
                                                    </span>
                                                    <span>-</span>
                                                </div>
                                                )
                                                }
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        )
                    })
                    }
                </div>

                <div className="match-detail-bottom">
                    <UpDown 
                        size="md"
                        mb="" 
                        upClick={() => { this.oddListDiv.current.scrollTo({ behavior: 'smooth', top: this.oddListDiv.current.scrollTop - 300 }) }} 
                        downClick={() => { this.oddListDiv.current.scrollTo({ behavior: 'smooth', top: this.oddListDiv.current.scrollTop + 300 }) }} 
                    />
                </div>                
            
            </div>

        </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config,
      coupon: state.coupon
  }
}

export default connect(msp)(injectIntl(CStatisticIframe))
