import React from "react"
import { IntlProvider } from "react-intl"
import * as Redux from '../redux'

import messages_en from "../translates/main/en.json"
import messages_tr from "../translates/main/tr.json"
import messages_fr from "../translates/main/fr.json"
import messages_az from "../translates/main/az.json"
import messages_cw from "../translates/main/cw.json"
import messages_aa from "../translates/main/aa.json"
import messages_de from "../translates/main/de.json"

import moment from 'moment-timezone'

import "moment/locale/tr"
import "moment/locale/en-gb"
import "moment/locale/de"
import "moment/locale/ar"
import "moment/locale/az"

const menu_messages = {
  en: messages_en,
  tr: messages_tr,
  de: messages_de,
  az: messages_az,
  aa: messages_aa,
  fr: messages_fr,
  cw: messages_cw
}

const Context = React.createContext()

class MainIntlProviderWrapper extends React.Component {
    
  state = {
    locale: "en",
    messages: menu_messages["en"]
  }

  render() {
    
    const { children } = this.props
    const { locale, messages } = this.state

    if (locale == "en") moment.locale("en-gb")
    else if (locale == "tr") moment.locale("tr")

    // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    // moment.tz.setDefault(tz)

    // console.log("tz", tz)

    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: language => {
            Redux.setLanguage(language)
            this.setState({
              locale: language,
              messages: menu_messages[language]
            })
          }
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    )
  }
}

export { MainIntlProviderWrapper, Context as IntlContext }
