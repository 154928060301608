import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from "react-intl"
import { Button } from '../../components'
import Select from 'react-select';
import "../../../assets/common/config.css"

// import { IntlContext } from '../../components/intl-providers/config-intl-provider'

class App extends React.Component {

  constructor(props) {
    super(props)
    // console.log(props)
    this.state = {
      screenOptions: [
        {value: "Main", label: "Main"},
        {value: "LiveMatches", label: "LiveMatches"},
        {value: "TombalaVideo", label: "TombalaVideo"}
      ],
      secondScreenOptions: [
        {value: "", label: "---"},
        {value: "Main", label: "Main"},
        {value: "LiveMatches", label: "LiveMatches"},
        {value: "TombalaVideo", label: "TombalaVideo"}
      ],      
      tabs: ["Display"],
      activeTab: "Display",
      config: {
        mainScreen: "Main",
        secondScreen: "",
        hasExternalDisplay: props.config.hasExternalDisplay,
        printer: 1,
        onlineCheck: 1,
        userType: "T",
        version: "0.3.93"
      }
    }
  }

  componentDidMount() {
  
  }

  setConfig(param, value) {
    let a = {...this.state.config}
    a[param] = value;
    this.setState({config: a})
  }

  saveConfig() {
    let a = this.state.config
    let b = JSON.stringify(a)

    window.ipcRenderer.send("saveConfig", b)

  }

  close() {
    window.ipcRenderer.send("closeConfigWindow", this.props.config)
  }

  render() {
    const { config } = this.props
    const { tabs, activeTab, screenOptions, secondScreenOptions } = this.state

    // console.log("config", config)

    return (
      <div className="config-main">
        <div className="title-container">
        
          <FormattedMessage id="Terminal Preferences" />
          
          <Button title="Close" onClick={() => { this.close() }} />
        
        </div>
        <div className="cont">

          <div className="cont-in">

            {/* <div className="left">

            {
            tabs.map((tab, index) => {
              return (
                <div 
                  onClick={() => { this.setState({activeTab: tab}) }}
                  style={{ height: `${(100 / tabs.length)}%` }} className={`w-100 bg-gray-${activeTab == tab ? "300" : "100"} flex items-center justify-center ${index != tabs.length - 1 && ('border-b')} border-solid border-gray-400 text-gray-${activeTab == tab ? "700" : "500"} font-black text-2xl cursor-pointer`} key={index}>
                  <FormattedMessage id={tab} />
                </div>
              )
            })
            }

            </div>   */}

            <div className="right">
            
            {
            activeTab == "Display" && (
            <>
            <h2 className="text-3xl font-bold text-gray-600 pt-8 pb-3"><FormattedMessage id="Display" /></h2>
            <p className="text-lg font-medium text-green-600"><FormattedMessage id="Total Available Display" /> : {config.displayCount}</p>
            <hr className="my-5" />
            
            <span className="w-100 block mb-3"><FormattedMessage id="Main Display" /></span>
            <Select
              className="mb-6"
                placeholder={<FormattedMessage id="Main Display" />}
                defaultValue={screenOptions.filter(x => x.value == this.state.config.mainScreen)[0] ?? ""}
                options={screenOptions}
                onChange={(e) => {  
                  this.setConfig("mainScreen", e.value) 
                }}
              />  
              
              {
              config.hasExternalDisplay && (
                <>
                <span className="w-100 block mb-3"><FormattedMessage id="Second Display" /></span>
                <Select
                  placeholder={<FormattedMessage id="Second Display" />}
                  defaultValue={secondScreenOptions.filter(x => x.value == this.state.config.secondScreen)[0] ?? ""}
                  options={secondScreenOptions}
                  onChange={(e) => { 
                    this.setConfig("secondScreen", e.value) 
                  }}
                />
                </>
              )
              }

            </>
            )
            }            

            {
            activeTab == "Printer" && (
            <>
            <h2 className="text-3xl font-bold text-gray-600 pt-8 pb-3"><FormattedMessage id="Printer" /></h2>
            <p className="text-lg font-medium text-green-600"><FormattedMessage id="Founded Printers" /> : {config.printerCount}</p>
            <hr className="my-5" />
            <div onClick={() => { this.setConfig("printer", 1) }} className="w-100 flex flex-row items-center justify-start mb-5 cursor-pointer">
              <div style={{ width: 18, height: 18 }} className={`border border-solid border-gray-500 rounded-full mr-5 ${this.state.config.printer == 1 && ("bg-green-500")}`}></div>
              <span><FormattedMessage id="Print with Printer Service" /></span>
            </div>                         
            <div onClick={() => { this.setConfig("printer", 2) }} className="w-100 flex flex-row items-center justify-start cursor-pointer">
            <div style={{ width: 18, height: 18 }} className={`border border-solid border-gray-500 rounded-full mr-5 ${this.state.config.printer == 2 && ("bg-green-500")}`}></div>
              <span><FormattedMessage id="Print with Terminal Program" /></span>
            </div>                                     
            </>
            )
            }

            {
            activeTab == "Network" && (
            <>
            <h2 className="text-3xl font-bold text-gray-600 pt-8 pb-3"><FormattedMessage id="Network" /></h2>
            <p className="text-lg font-medium text-white">s</p>
            <hr className="my-5" />
            <div onClick={() => { this.setConfig("onlineCheck", 1) }} className="w-100 flex flex-row items-start justify-start mb-5 cursor-pointer">
            <div style={{ width: 18, height: 18 }} className={`border border-solid border-gray-500 rounded-full mr-5 ${this.state.config.onlineCheck == 1 && ("bg-green-500")}`}></div>
              <span>
                <FormattedMessage id="Ping" />
                <br />
                <p className="mt-2 text-sm italic"><FormattedMessage id="Totally safe, bad performance" /></p>
              </span>
            </div>                         
            <div onClick={() => { this.setConfig("onlineCheck", 2) }} className="w-100 flex flex-row items-start justify-start cursor-pointer">
            <div style={{ width: 18, height: 18 }} className={`border border-solid border-gray-500 rounded-full mr-5 ${this.state.config.onlineCheck == 2 && ("bg-green-500")}`}></div>
              <span>
                <FormattedMessage id="Socket" />
                <br />
                <p className="mt-2 text-sm italic"><FormattedMessage id="Good performance, will get false negatives" /></p>                
              </span>
            </div>                                     
            </>
            )
            } 

            {
            activeTab == "User Type" && (
            <>
            <h2 className="text-3xl font-bold text-gray-600 pt-8 pb-3"><FormattedMessage id="User Type" /></h2>
            <p className="text-lg font-medium text-white">s</p>
            <hr className="my-5" />
            <div onClick={() => { this.setConfig("userType", "T") }} className="w-100 flex flex-row items-start justify-start mb-5 cursor-pointer">
            <div style={{ width: 18, height: 18 }} className={`border border-solid border-gray-500 rounded-full mr-5 ${this.state.config.userType == "T" && ("bg-green-500")}`}></div>
              <span>
                <FormattedMessage id="Terminal" />
                <br />
                <p className="mt-2 text-sm italic"><FormattedMessage id="With Money Acceptor" /></p>
              </span>
            </div>                         
            <div onClick={() => { this.setConfig("userType", "F") }} className="w-100 flex flex-row items-start justify-start cursor-pointer">
            <div style={{ width: 18, height: 18 }} className={`border border-solid border-gray-500 rounded-full mr-5 ${this.state.config.userType == "F" && ("bg-green-500")}`}></div>
              <span>
                <FormattedMessage id="Filliale" />
                <br />
                <p className="mt-2 text-sm italic"><FormattedMessage id="Without Money Acceptor" /></p>                
              </span>
            </div>                                     
            </>
            )
            }                                    

            </div>          
          
          </div>      

        </div>
        <div className="bottom">
          
          <Button title="Save" onClick={() => { this.saveConfig() }} />
        
        </div>        
      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(App)
