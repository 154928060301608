import React from 'react'
import { injectIntl } from 'react-intl'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { Redux, WebClient, constants } from '../../../components'
import assets from '../../../components/utils/assets'
import CButton from './components/button'
import moment from 'moment'
import MatchList from './components/match-list'
import SearchOverlay from './components/search-overlay'
import CStatisticsIframe from './components/statistics'
import CMatchDetail from './components/match-detail'
import classNames from 'classnames'
import _ from "lodash"

import Coupon from './coupon'

let limit = 75
let tournament_title = null
let scrollM = 0
let scrollV = 0
let pages = 1
let currentPage = 1
let bottomReached = false

class Matches extends React.Component {

  constructor(props) {
    super(props)
    this.wc = new WebClient();
    this.matchListDiv = React.createRef()
    this.state = {
      list: [0,0,0,0,0,0],
      activeSport: null,
      activeCategory: {id: -1},
      tournaments: [],
      activeTournament: null,
      sports: [],
      categories: [],
      timeSelection: 0,
      daySelection: -1,
      start: new Date(),
      end: moment(new Date(), "DD-MM-YYYY").add(45, 'days'),
      rawList: [],
      matches: [],
      postData: {},
      dayList: [],
      keyword: "",
      showSearchOverlay: false,
      showStatistics: false,
      statisticsUrl: "",
      matchDetail: [],
      showMatchDetail: false,
      selectedMatch: null,
      ready: false
    }
  }

  componentDidMount() {
    this._setDayList()
    this._getSports() 
  }

  _setDayList() {
    let a = []
    let b = new Date()
    for(let i = 1; i < 7; i++) {
      a.push(moment(b, "DD-MM-YYYY").add(i, 'days'))
    }
    this.setState({dayList: a})
  }

  _getSports() {

    const { start, end } = this.state

    this.wc.post(constants.uris.sprt, 
      {
        startDate: moment(start).format("YYYY-MM-DD HH:mm"),
        endDate: moment(end).format("YYYY-MM-DD HH:mm")
      }, 
      {
        setLang: true,
        setOperatorId: true,
      }).then((response) => {
        
        console.log(response)
        
        if (response.status) {

          let activeSport = null
          if (response.results?.length > 0) {
            activeSport = response.results[0]
            this._getCategories(activeSport)
          }

          this.setState({sports: response.results, activeSport})
        }

      }).catch((err) => {
          console.log(err)
      })

  }

  _getCategories(sport) {

    this.setState({activeSport: sport})

    const { start, end } = this.state

    this.wc.post(constants.uris.ctgr, 
      {
        startDate: moment(start).format("YYYY-MM-DD HH:mm"),
        endDate: moment(end).format("YYYY-MM-DD HH:mm"),
        sportId: sport.id
      }, 
      {
        setLang: true,
        setOperatorId: true,
      }).then((response) => {
        
        if (response.status) {

          let tm = response.results.reduce((a, b) => a + (b.matchCount || 0), 0)
          let b = [{
            title: this.props.intl.formatMessage({id: "select_all"}), 
            id: -1, 
            matchCount: tm, 
            flagSee: 1, 
            countryFlag: assets.select_all
          }, 
          ...response.results]
          let c = b[0]

          this.setState({
            categories: b, activeCategory: c})

          this._getMatches(this.state.activeSport.id, c.id == -1 ? undefined : c.id)
        }

      }).catch((err) => {
          console.log(err)
      })

  }

  _getTournaments(category) {
    this.setState({activeCategory: category, tournaments: []})

    const { start, end, activeSport } = this.state

    this.wc.post(constants.uris.tour, 
      {
        startDate: moment(start).format("YYYY-MM-DD HH:mm"),
        endDate: moment(end).format("YYYY-MM-DD HH:mm"),
        categoryId: category.id,
        sportId: activeSport.id
      }, 
      {
        setLang: true,
        setOperatorId: true,
      }).then((response) => {
        console.log(response)
        if (response.status) {
          
          let res = response.results
          this.setState({tournaments: res})
          this._getMatches(activeSport.id, category.id == -1 ? undefined : category.id)
        }
      }).catch((err) => {
          console.log(err)
      })

  }

  _getMatches(id, categoryId = undefined, tournamentId = undefined, keyWord = undefined) {

    this.setState({matches: [], ready: false})

    let odds = Redux.getNormalOddsBySportId(id)

    const { start, end, activeCategory } = this.state

    let p = Math.ceil(activeCategory.matchCount / limit)
    pages = p;

    let d = {
      startDate: moment(start).format("YYYY-MM-DD HH:mm"),
      endDate: moment(end).format("YYYY-MM-DD HH:mm"),
      sportId: id,
      categoryId,
      tournamentId,
      keyWord,
      odds,
      limit: limit
    }

    this.wc.post(constants.uris.mtch, d, 
      {
        setLang: true,
        setOperatorId: true,
      }, false).then((response) => {
        if (response.status) {

          let r = response.results
          this.setState({matches: r, postData: d})

        }

        this.setState({ready: true})

      }).catch((err) => {
          console.log(err)
      })

  } 

  _getMatchesNextPage() {

    let d = this.state.postData
    d.limit = (limit * (currentPage))

    this.wc.post(constants.uris.mtch, d, 
      {
        setLang: true,
        setOperatorId: true,
      }, false).then((response) => {
        if (response.status) {

          let r = response.results
          this.setState({matches: r, postData: d})

        }

        bottomReached = false

      })
  }   

  _getTournamentMatches(tournament) {
    const { activeSport, activeCategory } = this.state
    this.setState({activeTournament: tournament}, () => {
      this._getMatches(activeSport.id, activeCategory.id, tournament.id)
    })
  }

  _leftArrow() {
      if (scrollM > 0) {
        scrollM = scrollM - 1;
        this.l.scrollToIndex({animated: true, index: "" + scrollM})
      }
      else {
        scrollM = this.state.sports.length - 1;
        this.l.scrollToIndex({animated: false, index: "" + scrollM})
      } 
  }

  _rightArrow() {
      if (scrollM < this.state.sports.length - 1) {
        scrollM = scrollM + 1;
        this.l.scrollToIndex({animated: true, index: "" + scrollM})
      } 
      else {
        scrollM = 0;
        this.l.scrollToIndex({animated: false, index: "" + scrollM})
      } 
  }  

  _getToday() {
    var start = new Date();
    start.setHours(0,0,0,0);
    
    var end = new Date();
    end.setHours(23,59,59,999);

    console.log(start, end)

    this.setState({timeSelection: 2, daySelection: -1, start, end, keyword: ""}, () => {
      this._getSports()
    })
  }

  _get6Hours() {
    let start = new Date();
    let end = moment(start, "DD-MM-YYYY").add(6, 'hours');
    this.setState({timeSelection: 1, daySelection: -1, start, end, keyword: ""}, () => {
      this._getSports()
    })
  }

  _getAll() {
    let start = new Date();
    let end = moment(start, "DD-MM-YYYY").add(45, 'days');
    this.setState({timeSelection: 0, daySelection: -1, start, end, keyword: ""}, () => {
      this._getSports()
    })
  }

  _getByDay(st, index) {
    let start = st.toDate();
    start.setHours(0,0,0,0);
    var end = moment(start, "DD-MM-YYYY").add(1, 'days');
    console.log(start, end)

    this.setState({daySelection: index, timeSelection: -1, start, end, keyword: ""}, () => {
      this._getSports()
    })
  }  

  _search() {
    const { activeSport, keyword } = this.state
    this._getMatches(activeSport.id, undefined, undefined, keyword)
  }    

  _getMatchDetail(e) {

    this.wc.post(constants.uris.odds, 
      {
        matchId: e.id,
        getFull: 1
      }, 
      {
        setLang: true,
        setOperatorId: true,
        setUserId: true
      }).then((response) => {
        
        console.log(response)
        
        if (response.status) {
          this.setState({selectedMatch: e, matchDetail: response.results, showMatchDetail: true})
        }

      }).catch((err) => {
          console.log(err)
      })

  }  

  _listOnScroll() {
    console.log("currentPage", currentPage)
    if (pages > 1 && currentPage < pages) {
      let a = this.matchListDiv.current.scrollHeight
      let b = this.matchListDiv.current.scrollTop
      let c = this.matchListDiv.current.clientHeight
      let d = a - b
      if (d <= (c * 2) && !bottomReached) {
        console.log("bottom")
        bottomReached = true
        // this._getNextPage()
        currentPage = currentPage + 1
        this._getMatchesNextPage();
      }
    }

  }

  _getNextPage() {
    // const { rawList } = this.state
    // let nl = limit * currentPage
    currentPage = currentPage + 1

    this._getMatchesNextPage();

    // let a = rawList.slice(nl, nl + limit)
    // let b = [...this.state.matches, ...a]
    // this.setState({matches: b})
    // bottomReached = false
  }   

  render() {
    const { intl } = this.props
    const { sports, activeSport, categories, activeCategory, timeSelection, daySelection, tournaments, activeTournament, matches, dayList, keyword, showSearchOverlay, showStatistics, statisticsUrl, matchDetail, showMatchDetail, selectedMatch, ready } = this.state
    const width = window.innerWidth

    const showStaticCoupon = Boolean(width > 1439)

    return (
      <div className={classNames({
          "in-page-cont": !showStaticCoupon, "in-page-cont-2": showStaticCoupon
        })}>

        <div className={classNames({"in-page-layout-1": showStaticCoupon})}>
        <div className="in-page-top">

          <div className="in-page-top-col1">
            <CButton uppercase="uppercase" translate={true} width="w-1/3" text="all" active={Boolean(timeSelection == 0)} onClick={() => { this._getAll() }} />
            <CButton uppercase="uppercase" translate={true} width="w-1/3" text="6_hour" active={Boolean(timeSelection == 1)} onClick={() => { this._get6Hours() }} />
            <CButton uppercase="uppercase" translate={true} width="w-1/3" text="today" active={Boolean(timeSelection == 2)} onClick={() => { this._getToday() }} />
          </div>

          <div className={classNames({"in-page-top-col2": !showStaticCoupon, "in-page-top-col2-2": showStaticCoupon})}>

            {
            sports.length > 3 && (
            <CButton width="w-1/12" icon={true} iconName="fa-chevron-left" onClick={() => { this._leftArrow() }} />
            )
            }

            <div className={classNames({"in-page-top-col2-mid": !showStaticCoupon, "in-page-top-col2-mid-2": showStaticCoupon})}>

            <FlatList
              ref={(ref) => { this.l = ref }}
              data={sports}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              horizontal={true}
              renderItem={({item, index}) => {
                return (
                  <CButton 
                    key={index}
                    width={showStaticCoupon ? "w-spe2" : "w-spe"} 
                    uppercase="uppercase"
                    isSport={true}
                    sportId={item.id}
                    active={Boolean(activeSport?.id === item.id )} 
                    text={`${item.title} (${item.matchCount})`} 
                    onClick={() => { this._getCategories(item) }} 
                  />
                )
              }}
            />

            </div>

            {
            sports.length > 3 && (
            <CButton width="w-1/12" icon={true} iconName="fa-chevron-right" onClick={() => { this._rightArrow() }} />
            )
            }

          </div>          
          {
          (!showStaticCoupon) && (
          <div className="in-page-top-col3">
            <CButton uppercase="uppercase" translate={true} text="coupon" onClick={() => { this.props.onCouponOpen()  }} />
          </div> 
          )
          }         

        </div>

        <div className="in-page-middle">

          <div className="in-page-middle-left">
              <div className="search-cont">
                <input onClick={() => { this.setState({showSearchOverlay: true, keyword: ""}) }} defaultValue="" type="text" value={keyword} placeholder={intl.formatMessage({id: "match_search"})} />
                {
                keyword.length == 0 ?
                <i className="fas fa-search" />
                :
                <button onClick={() => { this._getAll() }} className="erase"><i className="fas fa-times" /></button>
                }
              </div>

              <div className="category-cont">

              <FlatList
                ref={(ref) => { this.categoryList = ref }}
                data={categories}
                style={{ width: "100%", height: "100%", paddingLeft: '.2vw', paddingRight: '.2vw' }}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                onScroll={(e) => { scrollV = e.nativeEvent.contentOffset.y }}
                renderItem={({item, index}) => {
                  return (
                    <>
                    <CButton 
                      key={index} 
                      type={2} 
                      flagSee={item.flagSee} 
                      countryFlag={item.countryFlag} 
                      active={Boolean(item.id === activeCategory.id)} 
                      text={item.title} 
                      count={item.matchCount} 
                      onClick={() => { this._getTournaments(item) }} 
                    />
                    {
                    activeCategory.id != -1 && activeCategory.id == item.id && tournaments.map((row, yindex) => {
                      return (
                        <CButton 
                          type={3} 
                          height="h-common" 
                          key={yindex} 
                          text={row.title} 
                          count={row.matchCount} 
                          active={Boolean(activeTournament?.id == row.id)}
                          onClick={() => { this._getTournamentMatches(row) }} 
                        />
                      )
                    })
                    }
                    </>
                  )
                }}
              />

              </div>
          </div>

          <div onScroll={() => { this._listOnScroll() }} ref={this.matchListDiv} className="in-page-middle-right">
            
            {
            ready && (
            <MatchList 
              matches={matches} 
              activeSport={activeSport} 
              onDetail={(e) => { this._getMatchDetail(e) }}
              onShowStatistics={(e) => { this.setState({showStatistics: true, statisticsUrl: e}) }} 
            />
            )
            }

          </div>          

        </div>

        <div className="in-page-bottom">

          <div className="in-page-bottom-col1">
          <CButton width="w-1/2" icon={true} iconName="fa-chevron-up" onClick={() => { this.categoryList.scrollToOffset({ animated: true, offset: scrollV - 200 }) }} />
          <CButton width="w-1/2" icon={true} iconName="fa-chevron-down" onClick={() => { this.categoryList.scrollToOffset({ animated: true, offset: scrollV + 200 }) }} />
          </div>

          <div className="in-page-bottom-col2">
          
          {
          dayList.map((item, index) => {
            return (
              <CButton 
                key={index} 
                width="w-125" 
                uppercase="uppercase" 
                active={Boolean(daySelection == index)} 
                text={moment(item).format('dddd')} 
                onClick={() => { this._getByDay(item, index) }} 
              />
            )
          })
          }

          <CButton width="w-125" icon={true} iconName="fa-chevron-up" onClick={() => { this.matchListDiv.current.scrollTo({ behavior: 'smooth', top: this.matchListDiv.current.scrollTop - 300 }) }} />
          <CButton width="w-125" icon={true} iconName="fa-chevron-down" onClick={() => { this.matchListDiv.current.scrollTo({ behavior: 'smooth', top: this.matchListDiv.current.scrollTop + 300 }) }} />

          </div>

        </div>

        </div>

        {
        (showStaticCoupon) && (
        <div className="static-coupon-container">
          <Coupon onClose={() => { }} hideClose={true} />
        </div>
        )
        }

          {
          showSearchOverlay && (
            <SearchOverlay 
              keyword={keyword}
              onChange={(e) => { this.setState({keyword: e}) }} 
              onClose={() => { this.setState({showSearchOverlay: false, keyword: ""}) }} 
              onSearch={() => { this.setState({showSearchOverlay: false}, () => {
                this._search();
              }) }}
            />
          )
          }       

          {
          showStatistics && (
            <CStatisticsIframe url={statisticsUrl} onClose={() => { this.setState({showStatistics: false, statisticsUrl: null}) }} />
          )
          }

          {
          showMatchDetail && (
            <CMatchDetail data={matchDetail} match={selectedMatch} onClose={() => { this.setState({showMatchDetail: false, matchDetail: []}) }} />
          )
          }                       

      </div>
    );
  }
}

const msp = (state) => {
  return {
      config: state.config,
      settings: state.settings
  }
}

export default connect(msp)(injectIntl(Matches))