import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { constants } from '..'

class Overlay extends React.Component {

  render() {
    return (
        <div className="lang-overlay">
          <div onClick={() => { this.props.onClose() }} className="overlay-close"><i className="far fa-window-close" /></div>
          {this.props.children}
        </div>
    );
  }
}

export default Overlay;